<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">അപേക്ഷകൾ</h3>
        </div>
    </div>
    <div id="search">
        <form class="search-form">
            <div class="form-group searchbox mal-text">
                <select as="select" class="form-control custom-select" v-model="filter_type" name="filter_type" id="select4" @change="getMembersList(1)">
                                    <option value>All</option>
                                    <option value="4">ശാഖാ അപേക്ഷകൾ</option>
                                    <option value="3">മണ്ഡലം അപേക്ഷകൾ</option>
                                    <option value="2">ജില്ലാ അപേക്ഷകൾ</option>
                                    <option value="1">സ്റ്റേറ്റ് അപേക്ഷകൾ</option>
                                </select>
                <i class="input-icon">
                                    <ion-icon name="filter-outline"></ion-icon>
                                </i>
            </div>
        </form>
    </div>
    <!-- <div class="section mt-1">
            <ul class="nav nav-tabs capsuled" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab" href="javascript:void(0)" role="tab"
                        @click="goToTab('Upcoming')">
                        <ion-icon name="list-outline" role="img" class="md hydrated" aria-label="home-outline"></ion-icon>
                        Ongoing
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="javascript:void(0)" role="tab" @click="goToTab('Old')">
                        <ion-icon name="timer-outline" role="img" class="md hydrated" aria-label="home-outline"></ion-icon>
                        Completed
                    </a>
                </li>
            </ul>
        </div> -->
    <div class="section text-center mt-1" v-if="loading">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="listed-detail mt-3" v-if="responseData.list.length == 0 && !loading">
        <h3 class="text-center mt-2 text-primary">No Data
        </h3>
    </div>
    <ul class="listview image-listview inset text mt-1 mb-2">
        <li v-for="(requestData , index) in responseData.list" v-bind:key="requestData.id" :style="{ 'background-color': background }">
            <router-link :to="requestData.requestDetails.edit?'request/' + requestData.id:'request-view/' + requestData.id">
                <a href="javascript:void(0)" class="item">
                    <span class="sl-no">{{ index+1 }}</span>
                    <div class="avatar-section">
                        <a href="#">
                            <img src="assets/img/sample/avatar/request.png" alt="avatar" class="image" />
                            <!-- <span class="button verified" v-if="member_list.member.verified == 'Y'">
                                                    <ion-icon name="checkmark-outline"></ion-icon>
                                                </span>
                                                <span class="button not-verified" v-if="member_list.member.verified == 'N' && member_list.member.verification_status">
                                                    <ion-icon name="close-outline"></ion-icon>
                                                </span> -->
                        </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <header>
                            <span class="text-primary">Request ID: #{{requestData.id}}</span> |
                            <span class="text-danger">Date: {{getFormattedDate(requestData.created_at)}}</span><br>
                            <span class="badge badge-secondary">{{requestData.requestDetails.wing}}</span>
                            <span class="badge badge-info" v-if="requestData.requestDetails.track_status == 'Request Received'">{{requestData.requestDetails.track_status}}</span>
                            <span class="badge badge-success" v-if="requestData.requestDetails.track_status == 'Request Completed'">{{requestData.requestDetails.track_status}}</span>
                            <span class="badge badge-primary" v-if="requestData.requestDetails.track_status == 'Request Created' || requestData.requestDetails.track_status == 'Request Forwarded'">{{requestData.requestDetails.track_status}}</span>
                            <span class="badge badge-danger" v-if="requestData.requestDetails.track_status == 'Request Rejected'">{{requestData.requestDetails.track_status}}</span>
                            <span class="badge badge-warning" v-if="requestData.requestDetails.track_status == 'Request Pending'">{{requestData.requestDetails.track_status}}</span>

                            
                        </header>
                        <b class="mal-text text-primary"> {{ requestData.requestDetails.type }} {{ requestData.requestDetails.from }} നൽകിയ അപേക്ഷ. </b>
                        <footer>
                            <span class="text-info" v-if="requestData.requestDetails.track_status == 'Request Received'">{{requestData.requestDetails.current_status}}</span>
                            <span class="text-success" v-if="requestData.requestDetails.track_status == 'Request Completed'">{{requestData.requestDetails.current_status}}</span>
                            <span class="text-primary" v-if="requestData.requestDetails.track_status == 'Request Created' || requestData.requestDetails.track_status == 'Request Forwarded'">{{requestData.requestDetails.current_status}}</span>
                            <span class="text-danger" v-if="requestData.requestDetails.track_status == 'Request Rejected'">{{requestData.requestDetails.current_status}}</span>
                            <span class="text-warning" v-if="requestData.requestDetails.track_status == 'Request Pending'">{{requestData.requestDetails.current_status}}</span>
                        </footer>
                    </div>
                    <!-- <div class="card-button dropdown">
                            <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal" :data-bs-target="
                        '#actionSheetInset' + member_list.id + '-' + member_list.child_id
                      " @click="showActionModel">
                                <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                            </button>
                        </div> -->
                </div>
                </a>
            </router-link>
        </li>
    </ul>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
// import { Form, Field } from "vee-validate";
import UserService from "../../services/user.service";
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import $ from 'jquery';
import moment from 'moment'
export default {
    name: 'Organization',
    components: {
        // Form,
        // Field,
        AlertModel,
        DialogModel
    },
    data() {
        return {
            loading: false,
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            tabHome: true,
            tabUpcoming: false,
            tabOld: false,
            responseData: {
                meeting: [],
                participants: [],
                agenda: [],
                minutes: [],
                list: []
            },

            message: "",
            user_level: "",
            permissions: [],
            sub_levels: [],
            sub_level_list_name: "",
            user_level_id: "",
            unit: [],
            nerpatham_target: '',
            sub_level_id: '',
            request_type_list: [],
            sub_level: [],
            filter_type: ''
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.user_level = this.currentUser.user_level;
            this.$root.updateParent("Loading...");
            if (this.user_level == 1) {
                this.user_level_id = 0;
            } else if (this.user_level == 2) {
                this.user_level_id = this.currentUser.district_id;
            } else if (this.user_level == 3) {
                this.user_level_id = this.currentUser.zone_id;
            } else if (this.user_level == 4) {
                this.user_level_id = this.currentUser.unit_id;
            }
            this.getMeetings();
        }
    },
    methods: {
        getFormattedDate(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        goToTab(tab) {
            this.loading = true;
            this.tabHome = false;
            this.tabUpcoming = false;
            this.tabOld = false;
            if (tab == 'Home') {
                this.tabHome = true;
                this.getMeetings('Home');
            } else if (tab == 'Upcoming') {
                this.tabUpcoming = true;
                this.getMeetings('Upcoming');
            } else {
                this.tabOld = true;
                this.getMeetings('Old');
            }
        },

        getMeetings() {
            this.loading = true;
            let data = {
                user_level_id: parseInt(this.$route.params.id),
                user_level: parseInt(this.$route.params.sub_level),
            }
            UserService.authPostRequest('get-requests', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading = false;
                        this.responseData = response.data.data;
                        this.$root.updateParent('Wsidom Guide Requests');
                    } else {
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );
        },
        clickSublevel(sub_level_id) {
            this.$router.push('/level-2-org/' + (this.user_level + 1) + '/' + sub_level_id);
        },
        nerpathamTarget(sub_level = null) {
            this.nerpatham_target = sub_level.nerpatham_target;
            this.sub_level_id = sub_level.id;
            $('#nerpathamTargetModel').modal('show');
        },
        addNerpathamTarget(data) {
            data.level = this.currentUser.user_level + 1;
            data.level_id = parseInt(this.sub_level_id);
            UserService.addAdmin('set-nerpatham-target', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.getSubLevels();
                        $('#nerpathamTargetModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.loading = false;

                    this.messafe = (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        }
    },
};
</script>

<style scoped>
.list-title {
    font-size: 16px;
    color: black;
}

.item {
    /* margin-bottom: 10px; */
}

.report-btn {
    width: 100% !important;
}

.add-member-btn {
    font-size: 16px !important;
    font-weight: 800 !important;
}

.wallet-footer-user {
    /* height: 46px !important; */
}

.text-only {
    font-size: 13px;
    font-weight: bold;
    color: black;
    /* margin-right: 5px; */
}

.chip-media {
    margin-left: 5px;
    width: 145px !important;
    padding-left: 25px !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.chip-icon {
    width: 50px !important;
}

.chip-label {
    padding: 0px 30px 0px 30px !important;
    font-size: 14px !important;
}

.bg-pink {
    background-color: #E6A6C7 !important;
}

.bg-pink-dark {
    background-color: #B85887 !important;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.menu-icon {
    font-size: 32px !important;
}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.back-color-primary {
    background: #1a418c !important;
}

.back-color-secondary {
    background: #666d79 !important;
}

.back-color-danger {
    background: #de6f56 !important;
}

.back-color-warning {
    background: #b19547 !important;
}

.back-color-red {
    background: #d55454 !important;
}

.back-color-violet {
    background: #072F5F !important;
}

.back-color-blue1 {
    background: #1a418c !important;
}

.back-color-blue2 {
    background: #1a418c !important;
}

.back-color-blue3 {
    background: #1a418c !important;
}

.back-color-blue4 {
    background: #1a418c !important;
}

.back-color-blue5 {
    background: #1a418c !important;
}

.back-color-blue6 {
    background: #1a418c !important;
}

.w-50 {
    width: 50px !important;
}

.goals .item {
    padding: 10px 10px;
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    margin-bottom: 10px;
}

.price-icon {
    /* margin-top: -1%; */
    font-size: 24px;
}

.chip-label[data-v-638c6bb6] {
    padding: 0px 5px 0px 5px !important;
    font-size: 14px !important;
}

.pad-5-left {
    padding-left: 5px;
}
.badge{
    border-radius: 0px !important;
}
</style>