<template>
    <div class="section text-center mt-2 mb-2" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section inset mt-1" v-if="!loading_list">
        <div class="wide-block pt-1 pb-1">
            <div class="profile-header">
                <div class="avatar-section">
                    <img v-if="profile.avatar == 'member.jpg'" src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="imaged w-100 rounded" />
                    <img v-else :src="'https://guide.msoftit.com/' + profile.avatar" alt="avatar" class="imaged w-100 rounded" />
                </div>
                <div class="profile-info">
                    <h3>{{ currentUser.name }}</h3>
                    <span>{{ currentUser.country_code }} {{ currentUser.phone }}</span>
                </div>
                <button class="qr-code-button" @click="showQrCode = !showQrCode">
                    <ion-icon name="qr-code-outline"></ion-icon>
                </button>
            </div>

            <ul class="listview flush transparent simple-listview no-space mt-1">
                <!-- <li>
                    <strong>മൊബൈൽ നമ്പർ</strong>
                    <span>{{ currentUser.country_code }} {{ currentUser.phone }}</span>
                </li> -->
                <li v-if="this.profile.zone">
                    <strong class="mal-text">എന്റെ ജില്ല </strong>
                    <span class="text-end">{{ this.profile.district }}</span>
                </li>
                <li v-if="this.profile.zone">
                    <strong class="mal-text">എന്റെ മണ്ഡലം </strong>
                    <span class="mal-text">{{ this.profile.zone }}</span>
                </li>
                <li v-if="this.profile.unit">
                    <strong class="mal-text">എന്റെ ശാഖ </strong>
                    <span class="text-end mal-text">{{ this.profile.unit }}</span>
                </li>
                <li v-if="this.profile.unit && currentUser.user_level <= 3">
                    <strong></strong>
                    <router-link class="mal-text custom-button" :to="'/org/4/' + currentUser.unit_id">
                        <span>
                            ശാഖയിലേക്ക് പോകാൻ ഇവിടെ അമർത്തുക
                        </span>
                    </router-link>
                </li>
                <li v-if="this.profile.campus">
                    <strong class="mal-text">എന്റെ ക്യാമ്പസ്</strong>
                    <span class="text-end">{{ this.profile.campus }}</span>
                </li>
            </ul>
        </div>
    </div>

    <div v-if="showQrCode" class="qr-scanner-modal" @click.self="showQrCode = false">
        <div class="qr-scanner-content">
        <QrcodeVue3 :width="200" :height="200" :value="qrCodeValue" :qrOptions="{
                typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H'
            }"
            :image="'assets/logo.png'" :imageOptions="{
                hideBackgroundDots: true, imageSize: 0.4, margin: 0, image: 'assets/logo.png'
            }"
            :dotsOptions="{
            type: 'extra-rounded', color: '#2355b3', gradient: {
                type: 'linear',
                rotation: 0,
                colorStops: [
                { offset: 0, color: '#2355b3' },
                { offset: 1, color: '#2355b3' }
                ]
            }
            }"
            :backgroundOptions="{ color: '#ffffff' }"
            :cornersSquareOptions="{ type: 'dot', color: '#2355b3' }"
            :cornersDotOptions="{ type: undefined, color: '#2355b3' }"
            fileExt="png"
            :download="false"
            myclass="my-qur"
            imgclass="img-qr"
            downloadButton="my-button"
            :downloadOptions="{ name: 'Wisdom Guide', extension: 'png' }"
        />
        </div>
    </div>

    <div class="section mt-1" v-if="!loading_list">
        <div class="transactions">
            <a href="/#/my-profile/nerpatham"
                class="item">
                <div class="detail">
                    <img src="https://guide.msoftit.com/uploads/image/1699440085.jpeg" alt="img" class="image-block imaged w48">
                    <div>
                        <strong class="mal-text">നേർപഥം</strong>
                        <p class="mal-text">നേർപഥം ചേർക്കാനും പുതുക്കാനും ഇവിടെ ക്ലിക്ക് ചെയ്യുക</p>
                    </div>
                </div>
                <div class="right">
                    <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
                </div>
            </a>
        </div>
    </div>
    <div class="section mt-1 mb-1" v-if="!loading_list && profile.member_exist">
        <div class="goals">
            <router-link :to="'bio-data'">
                <div class="item">
                    <img src="https://guide.msoftit.com/uploads/image/1709102511.png" alt="img" class="image-block imaged w48">
                    <div class="in">
                        <div>
                            <h4 class="mal-text">എന്റെ ബയോ ടാറ്റ ഫോം</h4>
                            <p class="mal-text">ബയോ ഡാറ്റ ഫിൽ ചെയ്യാൻ/എഡിറ്റ് ചെയ്യാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക</p>
                        </div>
                        <div class="right">
                            <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
                        </div>
                        <!-- <div v-if="this.profile.fillStatus == 100" class="success_label"></div> -->
                    </div>
                    <div class="progress">
                        <div class="progress-bar" :class="this.profile.fillStatus == 100 ? 'bg-success' : 'bg-primary'"
                            role="progressbar" :style="{ width: this.profile.fillStatus + '%' }" aria-valuenow="85"
                            aria-valuemin="0" aria-valuemax="100">{{ this.profile.fillStatus }}%
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
    <div class="section mt-1 mb-1" v-if="!loading_list && profile.member_exist">
        <div class="transactions">
            <router-link :to="'/qr-code-scanner'">
                <div class="item">
                    <div class="detail">
                        <img src="https://guide.msoftit.com/uploads/image/1709102533.png" alt="img" class="image-block imaged w48">
                        <div>
                            <h4>QR Code Scanner.</h4>
                            <p class="mal-text">ക്യു ആർ കോഡ് വെച് അറ്റന്റൻസ് മാർക്ക് ചെയ്യാൻ ഇവിടെ അമർത്തുക</p>
                        </div>
                    </div>
                    <div class="right">
                        <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
    <ul class="listview image-listview inset text mt-1 pt-1 mb-1" v-if="!loading_list && profile">
        <li>
            <router-link :to="'set-password'">
                <a href="javascript:void(0)" class="item">
                    <div class="in">
                        <div>
                            <header>Click to change your pin.</header>
                            <b class="mal-text">പിൻ നമ്പർ മാറ്റാൻ ഇവിടെ അമർത്തുക</b>
                        </div>
                    </div>
                </a>
            </router-link>
        </li>
        <li>
            <a href="javascript:void(0)" class="item">
                <div class="in">
                    <div>
                        <header>App Version</header>
                        <b> 2.0 </b>
                    </div>
                </div>
            </a>
        </li>
        <li>
            <a href="javascript:void(0)" class="item" @click="logout">
                <div class="in">
                    <div style="color: red;">
                        <b> Logout </b>
                    </div>
                </div>
            </a>
        </li>
    </ul>

</template>
<script>
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";
import QrcodeVue3 from "qrcode-vue3"; 
export default {
    name: 'Profile',
    components: {
        QrcodeVue3,
    },
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        }
    },
    data() {
        return {
            profile: [],
            loading_list: false,
            showQrCode: false,
            qrCodeValue: "",
        }
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        }
        this.loading_list = true;
        this.getProfile();
        this.$root.updateParent("My Profile");
    },
    methods: {
        getProfile() {
            var data = {
                user_id: this.currentUser.id
            }
            UserService.authPostRequest('get-my-profile', data).then(
                (response) => {
                    this.profile = response.data.data.list;
                    this.qrCodeValue = JSON.stringify({
                        user_id: this.currentUser.id
                    });
                    this.loading_list = false;
                    localStorage.setItem("my_profile", JSON.stringify(this.profile));
                },
            );
        },
        logout() {
            AuthService.logout();
            localStorage.activeMenu = 'Home';
            location.replace('/');
        }
    },
};
</script>
<style>
.col-8 {
    margin-top: -25px !important;
}

.listview {
    font-size: 14px !important;
}

.listview li {
    min-height: 25px !important;
}

.success_label {
    content: "";
    width: 25px;
    height: 25px;
    margin-top: 16px;
    margin-left: 38%;
    background: #1DCC70;
    border-radius: 100%;
    display: flex;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='18px' height='14px' viewBox='0 0 23 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline id='Path' stroke='%23FFFFFF' stroke-width='2.4' points='21.2 2 7.76 18 2 12'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.warning_label {
    content: "";
    width: 35px;
    height: 35px;
    margin-top: 16px;
    margin-left: 36%;
    background: #FFCC00;
    border-radius: 100%;
    display: flex;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='8px' height='8px' viewBox='0 0 8 8' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Ccircle id='Oval' fill='%23FFFFFF' cx='4' cy='4' r='4'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.danger_label {
    content: "";
    width: 35px;
    height: 35px;
    margin-top: 16px;
    margin-left: 36%;
    background: #ff0e0e;
    border-radius: 100%;
    display: flex;
    background-image: url("data:image/svg+xml,%0A%3Csvg width='23px' height='20px' viewBox='0 0 23 20' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline id='Path' stroke='%23FFFFFF' stroke-width='2.4' points='21.2 2 7.76 18 2 12'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}

.goals .item p {
    font-size: 10px;
}

.icon-size {
    font-size: 24px;
}

.footer-text {
    font-size: 12px;
}

.price {
    font-size: 50px;
}

.profile-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.avatar-section {
    flex: 0 0 auto;
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

.profile-info {
    flex-grow: 1;
    margin-left: 10px;
}

.profile-info h3 {
    margin: 0;
    font-size: 14px;
}

.profile-info span {
    display: block;
    margin-top: 5px;
    font-size: 13px;
    line-height: 10px;
}

.qr-code-button {
    background: #e6e6e6;
    color: #000748;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.qr-code-button ion-icon {
    width: 24px;
    height: 24px;
}

.qr-scanner-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    opacity: 0;
    animation: fadeIn 0.3s forwards;
}

.qr-scanner-content {
    background: white;
    padding: 5px;
    border-radius: 20px;
    text-align: center;
    transform: scale(0);
    animation: scaleUp 0.3s forwards;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@keyframes scaleUp {
    to {
        transform: scale(1);
    }
}

.custom-button {
    padding: 10px 20px;
    background-color: #e6e6e6;
    color: #000b82;
    text-align: center;
    font-weight: 500;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.3s ease;
}

.custom-button:hover {
    background-color: #d6d6d6;
}
</style>