<template>
    <div class="section text-center pt-1" v-if="loading_list">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section pt-1" v-if="!loading_list">
        <div class="wallet-card">
            <div class="balance">
                <div class="left">
                    <span class="title">Registrations</span>
                    <h1 class="total text-primary">{{ member_lists ? member_lists.total : '00' }}</h1>
                </div>
                <div class="right" v-if="responseData.permissions.add_new">
                    <a href="javascript:void(0);" class="button" @click="adminAdd">
                        <ion-icon name="add-outline"></ion-icon>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="section pt-0 mt-0" v-if="!loading_list">
        <div class="listed-detail mt-2 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">രജിസ്റ്റർ ചെയ്തവരുടെ ലിസ്റ്റ്</h3>
        </div>
    </div>
    <div class="section inset mt-1 mb-1"
        v-if="(currentUser.user_level <= 3 || responseData.permissions.checkin) && !loading_list && this.currentAdminLevel != 7">
        <div class="accordion" id="accordionExample2">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#accordion001" aria-expanded="false">
                        <ion-icon name="filter-outline" role="img" class="md hydrated"
                            aria-label="wallet outline"></ion-icon>
                        Filter
                    </button>
                </h2>
                <div id="accordion001" class="accordion-collapse collapse" data-bs-parent="#accordionExample2" style="">
                    <div class="accordion-body">
                        <div id="search" style="padding: 0px !important;">
                            <div class="form-group" v-if="currentUser.user_level == 1">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_district">District</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_district"
                                        name="filter_district" id="select4" @change="getZoneList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="district in district_list" v-bind:value="district.id"
                                            v-bind:key="district.id">{{
                                                district.name }}
                                        </option>
                                        <option value="NS">Not Selected</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 2 || responseData.permissions.checkin">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_zone">Zone</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_zone"
                                        name="filter_zone" id="select4" @change="getUnitList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="zone in zone_list" v-bind:value="zone.id" v-bind:key="zone.id">{{
                                            zone.name }}
                                        </option>
                                        <option value="NS">Not Selected</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 3 || responseData.permissions.checkin">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_unit">Unit</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_unit"
                                        name="filter_unit" id="select4">
                                        <option value>---All---</option>
                                        <option v-for="unit in unit_list" v-bind:value="unit.id" v-bind:key="unit.id">{{
                                            unit.name }}
                                        </option>
                                        <option value="NS">Not Selected</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 4">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_reg_type">Registration Type</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_reg_type"
                                        name="filter_reg_type" id="select4">
                                        <option value>---All---</option>
                                        <option value="local">Local</option>
                                        <option value="campus">Campus</option>
                                        <option value="public">Public</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 4">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_member">Member OR Not</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_member"
                                        name="filter_member" id="select4">
                                        <option value>---All---</option>
                                        <option value="member">Member</option>
                                        <option value="not_member">Not Member</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 4">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_sex">Sex</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_sex"
                                        name="filter_sex" id="select4">
                                        <option value>---All---</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 4">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_confirm">Confirmation Status</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_confirm"
                                        name="filter_confirm" id="select4">
                                        <option value>---All---</option>
                                        <option value="Y">Confirmed</option>
                                        <option value="N">Not Confirmed</option>
                                         <option value="MC">May Come</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 4">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_payment">Payment Status</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_payment"
                                        name="filter_payment" id="select4">
                                        <option value>---All---</option>
                                        <option value="Paid">Paid</option>
                                        <option value="Not Paid">Not Paid</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 4 && responseData.permissions.checkin">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_checkin">Checkin Status</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_checkin"
                                        name="filter_checkin" id="select4">
                                        <option value>---All---</option>
                                        <option value="Y">Checkin</option>
                                        <option value="N">Not Checkin</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group"
                                v-if="currentUser.user_level <= 4 && responseData.permissions.campus_filter">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_professional">Professional or Not</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_professional"
                                        name="filter_professional" id="select4">
                                        <option value>---All---</option>
                                        <option value="Y">Professional</option>
                                        <option value="N">Not Professional</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button type="button" class="btn btn-danger btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="clearFilter()">
                                        <ion-icon name="trash-bin-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Clear
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button type="button" class="btn btn-primary btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="getMembersList(null, null, 'local')">
                                        <ion-icon name="search-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section inset mt-1 mb-1"
        v-if="responseData.permissions.campus_filter && currentUser.user_level <= 2 && !loading_list && this.currentAdminLevel != 7">
        <div class="accordion" id="accordionExample3">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#accordion002" aria-expanded="false">
                        <ion-icon name="filter-outline" role="img" class="md hydrated"
                            aria-label="wallet outline"></ion-icon>
                        Campus Filter
                    </button>
                </h2>
                <div id="accordion002" class="accordion-collapse collapse" data-bs-parent="#accordionExample3" style="">
                    <div class="accordion-body">
                        <div id="search" style="padding: 0px !important;">
                            <div class="form-group">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_campus_district">District</label>
                                    <select as="select" filter class="form-control custom-select"
                                        v-model="filter_campus_district" name="filter_campus_district" id="select4"
                                        @change="getCollegeList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="district in campus_district_list" v-bind:value="district.item_value"
                                            v-bind:key="district.item_value">{{
                                                district.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_campus">Campus</label>
                                    <Field as="select" class="form-control custom-select" v-model="filter_campus"
                                        name="filter_campus" id="select4">
                                        <option value="" selected>---All---</option>
                                        <option v-for="campus in campus_list" v-bind:key="campus.id"
                                            v-bind:value="campus.id">
                                            {{ campus.name }}
                                        </option>
                                        <option value="others">Others</option>
                                    </Field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button type="button" class="btn btn-danger btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="clearFilter()">
                                        <ion-icon name="trash-bin-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Clear
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button type="button" class="btn btn-primary btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="getMembersList(null, null, 'campus')">
                                        <ion-icon name="search-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section inset mt-1 mb-1">
        <div id="search" style="padding: 0px !important;" v-if="!loading_list">
            <form class="search-form">
                <div class="row">
                    <div class="input-group form-group" style="width:70%">
                        <input type="text" placeholder="Search..." class="form-control" focus v-model="search_qry"
                            style="border-radius: 10px;" />
                    </div>
                    <div class="form-group" style="width:12% !important;">
                        <button type="button" class="btn btn-icon btn-outline-success me-1" @click="getMembersList">
                            <ion-icon name="search-outline" role="img" class="md hydrated"
                                aria-label="newspaper outline"></ion-icon>
                        </button>
                    </div>
                    <div class="form-group" style="width:12% !important;">
                        <button type="button" class="btn btn-icon btn-outline-primary me-1" @click="displayAll">
                            <ion-icon name="list-outline" role="img" class="md hydrated"
                                aria-label="newspaper outline"></ion-icon>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="section" v-if="!loading_list">
        <span><a :href="'https://guide.msoftit.com/export/event-export-data?user_level=' + currentUser.user_level + '&district_id='
            + currentUser.district_id + '&zone_id='
            + currentUser.zone_id + '&unit_id='
            + currentUser.unit_id + '&campus_id='
            + currentUser.campus_id + '&admin_level='
            + currentAdminLevel + '&type='
            + 'registration' + '&filter_district='
            + filter_district + '&filter_zone='
            + filter_zone + '&filter_sex='
            + filter_sex + '&filter_unit='
            + filter_unit + '&filter_campus_district='
            + filter_campus_district + '&filter_campus='
            + filter_campus + '&filter_reg_type='
            + filter_reg_type + '&filter_professional='
            + filter_professional + '&filter_confirm='
            + filter_confirm + '&filter_checkin='
            + filter_checkin + '&search_qry='
            + search_qry + '&filter_member='
            + filter_member + '&event_type='
            + $route.params.slug + ''
            " :download="'Profcon Registrations'">
                <ion-icon name="download-outline"></ion-icon> Download Excel
            </a></span>
    </div>
    <!-- AddAdminModel -->
    <div class="modal fade action-sheet" id="addAdminModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!add_admin">Edit</h5>
                    <h5 class="modal-title" v-if="add_admin">Add New</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="addAdmin" :validation-schema="schema">
                            <div class="form-group row boxed typehead-div">
                                <div class="input-wrapper col-12">
                                    <label class="label" for="text4b">Select From Members</label>
                                    <v-select label="text" :filterable="true" v-model="member_select" :value="member_select"
                                        :options="member_select_list" @input="getTextList($event.target.value, 0)">
                                        <template>
                                            type to search...
                                        </template>
                                        <template>
                                            <div class="d-center" @click="selectMember(member_select)">
                                                {{ member_select.name }}
                                            </div>
                                        </template>
                                    </v-select>
                                    <ErrorMessage name="member_select" class="error-feedback" />
                                </div>
                                <div class="input-info" v-if="member_select">Mobile :{{ member_select.country_code }} {{
                                    member_select.mobile
                                }}
                                </div>
                            </div>
                            <div class="form-group basic" v-if="!member_select">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="name">Name <span
                                            class="text-danger">*</span></label>
                                    <Field type="text" class="form-control" name="name" placeholder="Name" v-model="name"
                                        required />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="name" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="responseData.event.form.mobile && !member_select">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="mobile">Mobile <span
                                            class="text-danger">*</span></label>
                                    <Field type="text" class="form-control" id="mobile" name="mobile" placeholder="Mobile"
                                        v-model="mobile"
                                        oninput="javascript: if (this.value.length > 13) this.value = this.value.slice(0, 13);" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="mobile" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="responseData.event.form.sex && !member_select">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="gender">Sex <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" name="gender" id="gender"
                                        v-model="gander" required>
                                        <option value="" selected>Select Sex</option>
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="gender" class="error-feedback" />
                            </div>

                            <div class="form-group basic" v-if="responseData.event.form.age && !member_select">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="age">Age <span
                                            class="text-danger">*</span></label>
                                    <Field type="text" class="form-control" id="age" name="age" placeholder="age"
                                        v-model="age"
                                        oninput="javascript: if (this.value.length > 2) this.value = this.value.slice(0, 2);" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="age" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="currentUser.user_level == 1 && !member_select">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="district">District <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="district" name="district"
                                        id="select4" @change="getZoneList($event.target.value)">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="district in district_list" v-bind:key="district.id"
                                            :value="district.id">
                                            {{ district.name }}</option>
                                        <option value="others">Other</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="district" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="district == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_native_district">Enter district</label>
                                    <Field type="text" class="form-control" name="other_native_district" placeholder=""
                                        v-model="other_native_district" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_native_district" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="currentUser.user_level <= 2 && district <= 23 && !member_select">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="zone">Zone <span class="mal-text"></span></label>
                                    <Field as="select" class="form-control custom-select" v-model="zone" name="zone"
                                        id="select4" @change="getUnitList($event.target.value)">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="zone in zone_list" v-bind:key="zone.id" :value="zone.id">
                                            {{ zone.name }}</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="zone" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="currentUser.user_level <= 3 && district <= 23 && !member_select">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="unit">Unit <span class="mal-text"></span></label>
                                    <Field as="select" class="form-control custom-select" v-model="unit" name="unit"
                                        id="select4">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="unit in unit_list" v-bind:key="unit.id" :value="unit.id">
                                            {{ unit.name }}</option>

                                    </Field>
                                </div>
                                <ErrorMessage name="unit" class="error-feedback" />
                            </div>

                            <div class="form-group basic" v-if="responseData.event.form.place">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="place">Place</label>
                                    <Field type="text" class="form-control" name="place" placeholder="Place"
                                        v-model="place" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="place" class="error-feedback" />
                            </div>
                            <div class="form-group basic"
                                v-if="currentAdminLevel != 7 && (responseData.event.form.campus || responseData.event.form.school)">
                                <div class="input-wrapper">
                                    <label class="label text-primary"
                                        for="campus_district">{{ responseData.event.form.campus ? 'Campus' : '' }}
                                        {{ responseData.event.form.school ? 'School' : '' }} District <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="campus_district"
                                        name="campus_district" v-model="campus_district" @change="getCollegeList($event.target.value)">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="district in campus_district_list" v-bind:key="district.item_value"
                                            v-bind:value="district.item_value">
                                            {{ district.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="campus_district" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="campus_district == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_district">Enter Place Name</label>
                                    <Field type="text" class="form-control" name="other_district" placeholder=""
                                        v-model="other_district" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_district" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="responseData.event.form.campus && responseData.event.form.course_type">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="course_type">Course Type <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="course_type"
                                        name="course_type" v-model="course_type"
                                        @change="changeCourseType($event.target.value);">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="course_type in course_type_list" v-bind:key="course_type.id"
                                            v-bind:value="course_type.id">
                                            {{ course_type.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course_type" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="currentAdminLevel != 7 && responseData.event.form.campus">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="campus">College <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="campus" name="campus"
                                        id="select4">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="campus in campus_list" v-bind:key="campus.id"
                                            v-bind:value="campus.id">
                                            {{ campus.name }}
                                        </option>
                                        <option value="others">Others</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="campus" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="campus == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_college">Enter College Name</label>
                                    <Field type="text" class="form-control" name="other_college" placeholder=""
                                        v-model="other_college" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_college" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="responseData.event.form.campus">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="course">Course <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="course" name="course"
                                        id="select4">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="course in course_list" v-bind:key="course.id"
                                            v-bind:value="course.id">
                                            {{ course.name }}
                                        </option>
                                        <option value="others">Others</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="course == 'others' && responseData.event.form.campus">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_course">Enter Course Name</label>
                                    <Field type="text" class="form-control" name="other_course" placeholder=""
                                        v-model="other_course" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_course" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="responseData.event.form.school">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="school">School</label>
                                    <Field type="text" class="form-control" name="school" placeholder=""
                                        v-model="school" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="school" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="responseData.event.form.class">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="class_">Class <span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" v-model="class_" name="class_"
                                        id="select4">
                                        <option value="" selected>---Select---</option>
                                        <option value="Plus One">
                                            Plus One
                                        </option>
                                        <option value="Plus Two">
                                            Plus Two
                                        </option>
                                        <option value="others">Others</option>
                                    </Field>
                                </div>
                                <ErrorMessage name="class_" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="class_ == 'others'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="other_class">Enter Class</label>
                                    <Field type="text" class="form-control" name="other_class" placeholder=""
                                        v-model="other_class" />
                                    <i class="clear-input">
                                        <ion-icon name="close-circle"></ion-icon>
                                    </i>
                                </div>
                                <ErrorMessage name="other_class" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="responseData.event.form.campus">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="year_of_study">Year of Study<span
                                            class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="year_of_study"
                                        name="year_of_study" v-model="year_of_study">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="year_of_study in year_of_study_list" v-bind:key="year_of_study.id"
                                            v-bind:value="year_of_study.id">
                                            {{ year_of_study.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course_type" class="error-feedback" />
                            </div>
                            <div class="form-group basic" v-if="responseData.event.form.campus">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="year_of_completion">Year of course
                                        completion<span class="text-danger">*</span></label>
                                    <Field as="select" class="form-control custom-select" id="year_of_completion"
                                        name="year_of_completion" v-model="year_of_completion">
                                        <option value="" selected>---Select---</option>
                                        <option v-for="year_of_completion in year_of_completion_list"
                                            v-bind:key="year_of_completion.id" v-bind:value="year_of_completion.id">
                                            {{ year_of_completion.name }}
                                        </option>
                                    </Field>
                                </div>
                                <ErrorMessage name="course_type" class="error-feedback" />
                            </div>


                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                                    v-if="permissions.update">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!permissions.update"
                                    @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade action-sheet" id="confirmationModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirmation</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="confirmation" :validation-schema="schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="stay"><span>Confirmation Status</span><span
                                            class="text-danger">*</span></label>
                                    <select as="select" :disabled="confirmation_disabled" class="form-control custom-select"
                                        v-model="confirmation_status" name="confirmation_status" id="select4">
                                        <option value="Y">Confirmed</option>
                                        <option value="N">Not Confirmed</option>
                                        <option value="MC">May Come</option>
                                        <option value="NP" v-if="currentUser.user_level == 1 && responseData.event.form.campus">Not a Professional</option>
                                        <option value="P" v-if="currentUser.user_level == 1 && responseData.event.form.campus">Professional</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group basic" v-if="(confirmation_status == 'Y' || confirmation_status == 'MC') && responseData.event.form.campus">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="stay"><span>Confirmation From</span><span
                                            class="text-danger">*</span></label>
                                    <select as="select" :disabled="confirmation_disabled" class="form-control custom-select"
                                        v-model="confirmation_from" name="confirmation_from" id="select4">
                                        <option value="local">Local</option>
                                        <option value="campus">Campus</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group basic" v-if="confirmation_status == 'Y'">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="stay"><span>Payment Status</span><span
                                            class="text-danger">*</span></label>
                                    <select as="select" :disabled="confirmation_disabled" class="form-control custom-select"
                                        v-model="payment_status" name="payment_status" id="select4">
                                        <option value="Paid">Paid</option>
                                        <option value="Not Paid">Not Paid</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg"
                                    :disabled="loading && confirmation_disabled" v-if="permissions.update">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!permissions.update"
                                    @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade action-sheet" id="checkinModel" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Check-in</h5>
                </div>
                <div class="modal-body">
                    <div class="action-sheet-content">
                        <Form @submit="checkin" :validation-schema="schema">
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="stay"><span>Check-in Status</span><span
                                            class="text-danger">*</span></label>
                                    <select as="select" :disabled="checkin_disabled" class="form-control custom-select"
                                        v-model="checkin_status" name="checkin_status" id="select4">
                                        <option value="Y">Check-in</option>
                                        <option value="N">Not Check-in</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group basic">
                                <div class="input-wrapper">
                                    <label class="label text-primary" for="stay"><span>Payment Status</span><span
                                            class="text-danger">*</span></label>
                                    <select as="select" :disabled="checkin_disabled" class="form-control custom-select"
                                        v-model="payment_status" name="payment_status" id="select4">
                                        <option value="Paid">Paid</option>
                                        <option value="Not Paid">Not Paid</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group" v-if="message">
                                <div v-if="message" class="error-feedback">
                                    {{ message }}
                                </div>
                            </div>

                            <div class="form-group basic">
                                <button type="submit" class="btn btn-primary btn-block btn-lg"
                                    :disabled="loading && checkin_disabled" v-if="permissions.update">
                                    <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                                    <span>Save</span></button>
                                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!permissions.update"
                                    @click="closeAddAdminModel">Close</button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="listed-detail mt-0">
        <h4 class="text-center mt-0 mal-text" v-if="!loading_list && member_lists.total <= 0">ഇതുവരെ ആരെയും
            ചേർത്തിട്ടില്ല</h4>
    </div>

    <!-- <div class="section" v-if="!loading_list">
        <span><a :href="'https://batch.msoftit.com/reports/pdf/reportZonalCouncil.php?zone_id=' + unit.id + '&wing=6&mobile='+currentUser.phone"
                :download="'Girls Zonal Council'">
                <ion-icon name="download-outline"></ion-icon> Download PDF
            </a></span>
    </div> -->
    <ul class="listview image-listview inset text mt-1" v-if="!loading_list && member_lists.total > 0">
        <li v-for="(member_list, index) in member_lists.data" v-bind:key="member_list.id"
            :style="{ 'background-color': member_list.confirmation_status == 'NP' ? '#ffb3b3' : (member_list.registered_from == 'campus' ? '#ffffb3' : member_list.registered_from == 'public' ? '#b3ffec' : '#f2f2f2') }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ (member_lists.from + index) }}</span>
                <div class="avatar-section">
                    <a href="#">
                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                        <span class="button-payment payment-verified" v-if="member_list.payment_status == 'Paid'">
                            <ion-icon name="card-outline"></ion-icon>
                        </span>
                        <span class="button verified" v-if="member_list.confirmation_status == 'Y'">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>

                        <span class="button not-verified" v-if="member_list.confirmation_status == 'N'">
                            <ion-icon name="close-outline"></ion-icon>
                        </span>
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <header class="text-danger" v-if="member_list.reg_no">Reg No: {{member_list.reg_no}} | <span class="text-success" v-if="member_list.member_id">Member</span><span class="text-danger" v-else>Not Member</span></header>
                        <b> {{ member_list.name }} </b>
                        <footer class="text-primary">
                            <table>
                                <tr>
                                    <th>Mobile: <span class="text-dark">{{ member_list.mobile }} </span></th>
                                </tr>
                                <tr v-if="member_list.district">
                                    <th>District: <span class="text-dark">{{ member_list.district ?
                                        member_list.district.name : '' }} {{ member_list.district_name_other ? member_list.district_name_other : '' }}</span>
                                    </th>
                                </tr>
                                <tr v-if="member_list.zone">
                                    <th>Zone: <span class="text-dark">{{ member_list.zone ? member_list.zone.name : '' }}
                                        </span></th>
                                </tr>
                                <tr v-if="member_list.unit">
                                    <th>Unit: <span class="text-dark">{{ member_list.unit ? member_list.unit.name : '' }}
                                        </span></th>
                                </tr>
                                <tr v-if="member_list.place">
                                    <th>Place: <span class="text-dark">{{ member_list.place }}
                                        </span></th>
                                </tr>
                                <tr v-if="responseData.event.form.school">
                                    <th>School: <span class="text-dark">{{ member_list.school ? member_list.school : ''}}</span></th>
                                </tr>
                                <tr v-if="responseData.event.form.school">
                                    <th>Class: <span class="text-dark"> {{ member_list.class ? member_list.class : ''}} {{ member_list.class_other ? member_list.class_other : ''}}</span></th>
                                </tr>
                                <tr v-if="responseData.event.form.campus">
                                    <th>Campus: <span class="text-dark">{{ member_list.campus ? member_list.campus.name : ''
                                    }} {{ member_list.campus_other ? member_list.campus_other : ''
}} </span></th>
                                </tr>
                                <tr v-if="responseData.event.form.course">
                                    <th>Course: <span class="text-dark">{{ member_list.course ? member_list.course.name : ''
                                    }} {{ member_list.course_other ? member_list.course_other : ''
}} </span></th>
                                </tr>
                                <tr v-if="responseData.event.form.job_type">
                                    <th>Job Type: <span class="text-dark">
                                            {{ member_list.job_type_details }}
                                            {{ member_list.job_type_other }}
                                        </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.job_job">
                                    <th>Job: <span class="text-dark">
                                            {{ member_list.job }}
                                        </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.job_instituion_level">
                                    <th>Institution Level: <span class="text-dark">
                                            {{ member_list.institution_level }} {{ member_list.institution_level_other }}
                                        </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.job_company">
                                    <th>{{responseData.event.form.job_company_text?responseData.event.form.job_company_text:'Institution'}} <span class="text-dark">
                                            {{ member_list.company }} {{ member_list.company_text }}
                                        </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.job_designation">
                                    <th>Designation: <span class="text-dark">
                                            {{ member_list.designation }}
                                        </span>
                                    </th>
                                </tr>
                                <tr>
                                    <th>Registered From: <span class="text-dark">{{ member_list.registered_from }} </span>
                                    </th>
                                </tr>
                                <tr v-if="member_list.registered">
                                    <th>Updated By: <span class="text-dark">{{ member_list.registered ?
                                        member_list.registered.name : '' }}
                                        </span></th>
                                </tr>
                                <tr v-if="member_list.confirmation_from">
                                    <th>Confirmed From: <span class="text-dark">{{ member_list.confirmation_from }} </span>
                                    </th>
                                </tr>
                                <tr v-if="responseData.event.form.professional">
                                    <th v-if="member_list.confirmation_status == 'NP'" class="text-danger">Not a
                                        Professional: <span>State Admin</span></th>
                                    <th v-else>Confirmed By: <span class="text-dark">{{ member_list.confirmed_by_name ?
                                        member_list.confirmed_by_name
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="member_list.remarks">
                                    <th>Remarks: <span class="text-dark">{{ member_list.remarks ? member_list.remarks
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="member_list.checkin_status" style="border-top: solid 1px;
                                border-left: solid 1px;
                                border-right: solid 1px;">
                                    <th>Check-in Status: <span class="text-success">{{ member_list.checkin_status == 'Y' ?
                                        'Checkin'
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="member_list.checkin_by_name" style="border-left: solid 1px;
                                border-right: solid 1px;"
                                    :v-bind:style="!member_list.bus ? 'border-bottom: solid 1px' : ''">
                                    <th>Check-in By: <span class="text-dark">{{ member_list.checkin_by_name ?
                                        member_list.checkin_by_name
                                        : '' }} </span></th>
                                </tr>
                                <tr v-if="member_list.bus" style="border-bottom: solid 1px;
                                border-left: solid 1px;
                                border-right: solid 1px;">
                                    <th>Check-in from: <span class="text-danger">{{ member_list.bus ?
                                        member_list.bus.bus_code
                                        : '' }} </span></th>
                                </tr>
                            </table>
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li
                                            v-if="responseData.permissions.checkin && member_list.confirmation_status != 'NP'">
                                            <a href="javascript:void(0)" @click="checkinView(member_list)"
                                                class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="bus-outline"></ion-icon> Check-in to Bus
                                                </span>
                                            </a>
                                        </li>
                                        <li
                                            v-if="responseData.permissions.confirmation && (member_list.confirmation_status != 'NP' || currentUser.user_level == 1)">
                                            <a href="javascript:void(0)" @click="confirmationView(member_list)"
                                                class="btn btn-list text-success" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="checkmark-done-outline"></ion-icon> Confirmation
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="responseData.permissions.edit">
                                            <a href="javascript:void(0)" @click="adminView(member_list)"
                                                class="btn btn-list text-primary" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> Edit
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" @click="adminDeleteConfirmation(member_list)"
                                                class="btn btn-list text-warning" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="trash-outline"></ion-icon> Remove
                                                </span>
                                            </a>
                                        </li>
                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section mt-2" v-if="!loading_list">
        <vue-awesome-paginate v-if="member_lists.total > 0" :total-items="member_lists.total"
            :items-per-page="member_lists.per_page" :max-pages-shown="3" v-model="member_lists.current_page"
            :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
import UserService from "../../services/user.service";
import vSelect from 'vue-select'
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
// import * as yup from "yup";
export default {
    name: 'Admin List',
    components: {
        Form,
        Field,
        vSelect,
        ErrorMessage,
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        //     const schema_non_member = yup.object().shape({
        //         name: yup.string().when("isMember", {
        //     is: 'N',
        //     then: yup.string().required('ഫിൽ ചെയ്യുക!')
        //   }),
        //         age: yup.string().required('ഫിൽ ചെയ്യുക!'),
        //         gender: yup.string().required('സെലക്റ്റ് ചെയ്യുക!'),
        //     });
        return {
            loading: false,
            loading_list: false,
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            // schema_non_member,
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member_select_list:[],
            member_select : null,
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            sub_level_name: '',
            responseData: {
                event: {
                    form: []
                },
                questians: [],
                permissions: []
            },
            questians_responses: [],

            isYourZonal: '',
            district_list: [],
            campus_district_list: [],
            zone_list: [],
            unit_list: [],
            district: '',
            zone: '',
            unit: '',
            class_:'',
            school:'',
            other_class:'',
            filter_checkin: '',
            campus_district: '',
            other_district: '',
            campus_list: [],
            campus: '',
            other_college: '',
            course_list: [],
            course: '',
            other_course: '',
            course_type_list: [],
            course_type: '',
            class_list: [],

            filter_district: '',
            filter_member: '',
            filter_zone: '',
            filter_unit: '',
            year_of_completion: '',
            year_of_study: '',
            currentAdminLevel: '',
            filter_campus: '',
            filter_campus_district: '',
            filter_reg_type: '',
            confirmation_status: '',
            confirmation_disabled: false,
            checkin_disabled: false,
            payment_status: '',
            filter_confirm: '',
            filter_payment: '',
            filter_sex: '',
            confirmation_from: '',
            checkin_status: 'Y',
            filter_professional: '',
            search_qry: '',
            other_native_district: '',
            year_of_completion_list: [
                {
                    id: 2024,
                    name: '2024'
                },
                {
                    id: 2025,
                    name: '2025'
                },
                {
                    id: 2026,
                    name: '2026'
                },
                {
                    id: 2027,
                    name: '2027'
                },
                {
                    id: 2028,
                    name: '2028'
                },
                {
                    id: 2029,
                    name: '2029'
                }
            ],
            year_of_study_list: [
                {
                    id: 1,
                    name: '1'
                },
                {
                    id: 2,
                    name: '2'
                },
                {
                    id: 3,
                    name: '3'
                },
                {
                    id: 4,
                    name: '4'
                },
                {
                    id: 5,
                    name: '5'
                },
                {
                    id: 6,
                    name: '6'
                },
            ]

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.unit.nerpatham_target - this.member_lists.total;
            if (value <= 0) {
                return '0';
            } else {
                return value;
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.currentAdminLevel = localStorage.getItem('currentAdminLevel');
            this.getMembersList(1, this.currentAdminLevel);
            this.$root.updateParent('Loading...');
        }
        this.getComboboxValues('COUNTRY_CODE');
        this.getComboboxValues("DISTRICT_KERALA");
        this.getComboboxValues("PROFCON_COURSE_TYPE");
        this.getDistrictList();
        this.getZoneList(this.currentUser.district_id);
        
        this.isYourZonal = 'Y';
        this.changeIsZonal('Y');
        if (this.currentAdminLevel <= 4) {
            this.confirmation_from = 'local';
        } else {
            this.confirmation_from = 'campus';
        }

        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id
            }
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case 'COUNTRY_CODE':
                            this.country_code_list = response.data.data.list.options;
                            break;
                        case "DISTRICT_KERALA":
                            this.campus_district_list = response.data.data.list.options;
                            break;
                        case "PROFCON_COURSES":
                            this.course_list = response.data.data.list.options;
                            break;
                        case "SCHOOL_CLASS":
                            this.class_list = response.data.data.list.options;
                            break;
                        case "PROFCON_COURSE_TYPE":
                            this.course_type_list = response.data.data.list.options;
                            break;

                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getTextList(event, type) {
            this.message = '';
            let data = {
                q: event,
                type: type,
                filter: 'inside',
                unit_id: this.currentUser.unit_id,
                user_level: this.currentUser.user_level
            }
            UserService.authPostRequest('get-typehead-values', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_select_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        adminView(admin) {
            $('#addAdminModel').modal('show');
            this.message = '';
            this.isEdit = true;
            if (admin.zone_id == this.currentUser.zone_id) {
                this.isYourZonal = 'Y';
                this.getZoneList(this.currentUser.district_id);
                this.getUnitList(this.currentUser.zone_id);
            } else if (admin.district_id != 0) {
                this.isYourZonal = 'N';
                this.getDistrictList();
                if (admin.zone_id) {
                    this.getUnitList(admin.zone_id);
                }
                this.getZoneList(admin.district_id);
            }

            this.getComboboxValues("DISTRICT_KERALA");
            // if (admin.district_id) {
            //     this.getZoneList(admin.district_id);
            // }
            // this.getComboboxValues("PROFCON_COURSES");
            this.name = admin.name;
            this.mobile = admin.mobile;
            this.gander = admin.sex == 'Male' ? 'M' : 'F';
            this.unit = admin.unit_id;
            this.district = admin.district_id == 0 ? 'others' : admin.district_id;
            this.other_native_district = admin.other_native_district;
            this.zone = admin.zone_id;
            this.place = admin.place;
            this.course_type = admin.course_type_id;
            this.getComboboxValues("PROFCON_COURSES", admin.course_type);
            this.campus_district = admin.campus_district_id == 0 ? 'others' : admin.campus_district_id;
            this.getCollegeList();
            this.course = admin.course_id == 0 ? 'others' : admin.course_id;
            this.campus = admin.campus_id == 0 ? 'others' : admin.campus_id;

            this.other_college = admin.campus_other;
            this.other_course = admin.course_other;
            this.other_district = admin.campus_district_other;

            this.year_of_study = admin.year_of_study;
            this.year_of_completion = admin.year_of_completion;
            this.school = admin.school;
            this.class_ = admin.class;
            this.other_class = admin.class_other;
            this.age = admin.age;
            
            this.editable_id = admin.id;
            this.add_admin = false;
            this.edit_admin = true;
        },
        adminAdd() {
            $('#addAdminModel').modal('show');
            this.getZoneList(this.currentUser.district_id);
            this.getUnitList(this.currentUser.zone_id);
            this.disable_member_form = false;
            this.message = '';
            this.member_id = '';
            this.name = '';
            this.mobile = '';
            this.subscription_no = '';
            this.receipt_no = '';
            this.member_edit = true;
            this.member = '';
            this.editable_id = null;
            this.add_admin = true;
            this.edit_admin = false;
            this.unit = this.currentUser.unit_id;
        },
        closeAddAdminModel() {
            $('#addAdminModel').modal('hide');
        },
        addAdmin(user) {
            user.editable_id = this.editable_id;
            user.event_type = this.$route.params.slug;
            user.is_your_zone = this.isYourZonal;
            user.admin_level = this.currentAdminLevel;
            if (this.currentAdminLevel == 7) {
                user.campus_district = this.campus_district;
                user.campus = this.campus;
            }
            if(this.member_select){
                user.name = this.member_select.name;
                user.mobile = this.member_select.country_code + this.member_select.mobile;
                user.gender = (this.member_select.wing == 1 || this.member_select.wing == 2 || this.member_select.wing == 3) ? 'M' :'F';
                user.age = this.member_select.age;
                user.district = this.member_select.district_id;
                user.zone = this.member_select.zone_id;
                user.unit = this.member_select.unit_id;
            }
            UserService.addAdmin('store-event-registration', user).then(
                (response) => {
                    if (response.data.status == 'success') {
                        $('#addAdminModel').modal('hide')
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(this.member_lists.current_page, this.currentAdminLevel);
                        this.loading = false;
                        this.message = '';
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else if (response.data.status == 'error') {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                (error) => {
                    this.alert_type = 'Error';
                    this.alert_message = (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                        error.message ||
                        error.toString();
                    this.$refs.alert_model.openModel();
                    this.loading = false;

                }
            );
        },
        adminDeleteConfirmation(admin_list) {
            var data = {
                id: admin_list.id,
            }
            this.dialog_action_params = data;
            this.dialog_message = 'ശരിക്കും ഡിലീറ്റ് ചെയ്യണോ?';
            this.dialog_action = 'adminDelete';
            this.$refs.dialog_model.openModel();
            this.loading = false;
        },
        adminDelete(data) {
            UserService.authPostRequest('delete-event-registration', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(this.member_lists.current_page, this.currentAdminLevel);
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getWing(wing) {
            if (wing == 1)
                return 'വിസ്‌ഡം';
            else if (wing == 2)
                return 'യൂത്ത്';
            else if (wing == 3)
                return 'സ്റ്റുഡന്റസ്';
            else if (wing == 4)
                return 'വിമെൻ';
            else
                return 'ഗേൾസ്';
        },
        displayAll() {
            this.search_qry = '';
            this.getMembersList();
        },
        getMembersList(page = 1, currentAdminLevel = null, filter = false) {
            this.loading_list = true;
            if (filter == 'campus') {
                this.filter_district = '';
                this.filter_zone = '';
                this.filter_unit = '';
            }
            if (filter == 'local') {
                this.filter_campus_district = '';
                this.filter_campus = '';
            }
            let data = {
                event: this.$route.params.slug,
                type: 'registration',
                filter_district: this.filter_district ? this.filter_district : '',
                filter_campus_district: this.filter_campus_district ? this.filter_campus_district : '',
                filter_campus: this.filter_campus ? this.filter_campus : '',
                filter_zone: this.filter_zone ? this.filter_zone : '',
                filter_unit: this.filter_unit ? this.filter_unit : '',
                filter_reg_type: this.filter_reg_type ? this.filter_reg_type : '',
                filter_confirm: this.filter_confirm ? this.filter_confirm : '',
                filter_payment: this.filter_payment ? this.filter_payment : '',
                filter_sex: this.filter_sex ? this.filter_sex : '',
                filter_professional: this.filter_professional ? this.filter_professional : '',
                search_qry: this.search_qry ? this.search_qry : '',
                filter_checkin: this.filter_checkin ? this.filter_checkin : '',
                filter_member: this.filter_member ? this.filter_member : '',

                admin_level: currentAdminLevel ? currentAdminLevel : this.currentAdminLevel
            }
            UserService.authPostRequest(`get-event-registrations?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.permissions = response.data.data.permissions;
                        this.course_type = this.responseData.event.form.course_type_default;
                        if(this.course_type){
                            this.getComboboxValues("PROFCON_COURSES", this.course_type);
                        }
                        this.member_lists = response.data.data.list;
                        this.search_qty = '';
                        this.orgnal_member_lists = response.data.data.list.data;
                        this.unit = response.data.data.unit;
                        if (this.responseData.questians) {
                            let count = 0;
                            this.responseData.questians.forEach(element => {
                                this.questians_responses[count] = element.answer_text;
                                count++;
                            });
                        }
                        if (this.responseData.campus) {
                            this.campus_district = this.responseData.campus.campus_district_id;
                            this.campus = this.responseData.campus.id;
                        }
                        this.$root.updateParent(this.responseData.event.event_name);
                        this.loading_list = false;
                    } else {
                        this.responseData = response.data.data;
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeIsZonal(is_zonal) {
            if (is_zonal == 'Y') {
                this.getUnitList(this.currentUser.zone_id);
            } else {
                this.getDistrictList();
            }
        },
        getDistrictList() {
            // this.loading_list = true;
            let data = {}
            UserService.authPostRequest(`get-district-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.district_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getZoneList(district_id) {
            // this.loading_list = true;
            let data = {
                district_id: district_id
            }
            UserService.authPostRequest(`get-zone-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.zone_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getUnitList(zone_id) {
            // this.loading_list = true;
            let data = {
                zone_id: zone_id
            }
            UserService.authPostRequest(`get-unit-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.unit_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        changeCourseType(item) {
            this.getCollegeList();
            this.getComboboxValues("PROFCON_COURSES", item);
        },
        getCollegeList(district_id = null) {
            let data = {
                district_id: district_id ? district_id : this.campus_district,
                stream_id: this.course_type ? this.course_type : '',
            }
            if (district_id) {
                data.district_id = district_id;
            }
            UserService.authPostRequest(`get-campus-list`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.campus_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        clearFilter() {
            this.filter_district = '';
            this.filter_zone = '';
            this.filter_unit = '';
            this.getMembersList();
        },
        confirmationView(admin) {
            this.editable_id = admin.id;
            this.confirmation_status = admin.confirmation_status;
            if (admin.confirmation_from) {
                this.confirmation_from = admin.confirmation_from;
            }

            this.payment_status = admin.payment_status;
            if (admin.confirmation_from == 'local' && this.currentAdminLevel == 7) {
                this.confirmation_disabled = true;
            }
            if (admin.confirmation_from == 'campus' && (this.currentAdminLevel == 3 || this.currentAdminLevel == 4)) {
                this.confirmation_disabled = true;
            }
            $('#confirmationModel').modal('show');
        },
        confirmation(data) {
            this.loading = true;
            data.id = this.editable_id;
            data.confirmation_status = this.confirmation_status;
            data.payment_status = this.payment_status;
            data.confirmation_from = this.confirmation_from;
            UserService.authPostRequest('confirmation-event-registration', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(this.member_lists.current_page, this.currentAdminLevel);
                        $('#confirmationModel').modal('hide');
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },

        checkinView(admin) {
            this.editable_id = admin.id;
            if (admin.checkin_status != null) {
                this.checkin_status = admin.checkin_status;
            }
            this.payment_status = admin.payment_status;
            $('#checkinModel').modal('show');
        },
        checkin(data) {
            this.loading = true;
            data.id = this.editable_id;
            data.checkin_status = this.checkin_status;
            data.payment_status = this.payment_status;
            UserService.authPostRequest('checkin-event-registration', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.alert_type = 'Success';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.getMembersList(this.member_lists.current_page, this.currentAdminLevel);
                        $('#checkinModel').modal('hide');
                        this.loading = false;
                    } else if (response.data.status == 'warning') {
                        this.alert_type = 'Warning';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = false;
                    } else {
                        this.alert_type = 'Error';
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.loading = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.questians-label {
    font-weight: 600 !important;
    font-size: 14px !important;

}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .payment-verified {
    background: #05d0a4;
}



.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.avatar-section .button-payment {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 5px;
    bottom: 0;
}</style>