<template>
  <div id="search">
    <form class="search-form">
      <div class="form-group searchbox">
        <select as="select" class="form-control custom-select" v-model="filter_department" name="filter_department"
          id="select4" @change="getMembersList(1, $event.target.value, search_qty, filter_type, filter_wing, 'dept')">
          <option value>All-Registered List</option>
          <option class="mal-text" v-for="department in department_list" v-bind:key="department"
            v-bind:value="department.id">
            {{ department.name }}
          </option>
        </select>
        <i class="input-icon">
          <ion-icon name="filter-outline"></ion-icon>
        </i>
      </div>
      <div class="form-group searchbox mt-1">
        <select as="select" class="form-control custom-select" v-model="filter_wing" name="filter_department" id="select4"
          @change="getMembersList(1, filter_department, search_qty, filter_type, $event.target.value, 'wing')">
          <option value>All-Assigned List</option>
          <option class="mal-text" v-for="department_wing in wing_department_list" v-bind:key="department_wing"
            v-bind:value="department_wing.id">
            {{ department_wing.name }}
          </option>
        </select>
        <i class="input-icon">
          <ion-icon name="filter-outline"></ion-icon>
        </i>
      </div>
      <div class="form-group searchbox mt-1">
        <select as="select" class="form-control custom-select" v-model="filter_type" name="filter_type" id="select4"
          @change="getMembersList(1, filter_department, search_qty, $event.target.value, filter_wing)">
          <option value="gents" selected>Gents</option>
          <option value="ladies">Ladies</option>
        </select>
        <i class="input-icon">
          <ion-icon name="filter-outline"></ion-icon>
        </i>
      </div>
      <div class="form-group searchbox mt-1">
        <select as="select" class="form-control custom-select" v-model="filter_wings" name="filter_wings" id="select4"
          @change="getMembersList(1)">
          <option value>All Wings</option>
          <option value="1">Wisdom</option>
          <option value="2">Youth</option>
          <option value="3">Students</option>
          <option value="4">Women</option>
          <option value="6">Girls</option>
        </select>
        <i class="input-icon">
          <ion-icon name="filter-outline"></ion-icon>
        </i>
      </div>
      <div class="form-group searchbox mt-1">
        <select as="select" class="form-control custom-select" v-model="filter_district" name="filter_department"
          id="select4" @change="getMembersList(1)">
          <option value>All-Districts</option>
          <option v-for="district in district_list" v-bind:value="district.id" v-bind:key="district.id">{{
            district.name }}
          </option>
        </select>
        <i class="input-icon">
          <ion-icon name="filter-outline"></ion-icon>
        </i>
      </div>
    </form>
  </div>
  <div id="search">
    <form class="search-form">
      <div class="form-group searchbox">
        <input type="text" class="form-control" placeholder="ഇവിടെ സെർച്ച് ചെയ്യുക..." v-model="search_qty"
          @input="getMembersList(1, filter_wing, search_qty, filter_zone, filter_wing)" />
        <i class="input-icon">
          <ion-icon name="search-outline"></ion-icon>
        </i>
      </div>
    </form>
  </div>
  <div class="section" v-if="!loading_list && responseData.permissions.volunteer_download">
    <span v-if="responseData.download_pdf_link"><a :href="responseData.download_pdf_link"
        :download="'Volunteer Pdf List'">
        <ion-icon name="download-outline"></ion-icon> Download PDF
      </a></span>
    <span v-if="responseData.download_excel_link"><a :href="responseData.download_excel_link"
        :download="'Volunteer Excel List'">
        <ion-icon name="download-outline"></ion-icon> Download Excel
      </a></span>
  </div>
  <div class="section text-center mt-2" v-if="loading_list">
    <div class="spinner-border text-secondary" role="status"></div>
  </div>
  <div class="listed-detail mt-1" v-if="!loading_list && responseData.members.total == 0">
    <h4 class="text-center mt-0 text-primary">No Data</h4>
  </div>
  <ul class="listview image-listview inset text mt-2" v-if="!loading_list">
    <li v-for="(member_list, index) in responseData.members.data" v-bind:key="member_list.member.id">
      <a href="javascript:void(0)" class="item" v-if="member_list.member">
        <span class="sl-no">{{ responseData.members.from + index }}</span>
        <div class="avatar-section">
          <a href="#">
            <img v-if="member_list.member.avatar == 'member.jpg'" src="assets/img/sample/avatar/avatar1.png" alt="avatar"
              class="image">
            <img v-else :src="'https://guide.msoftit.com/' + member_list.member.avatar" alt="avatar" class="image">
          </a>
        </div>
        <div class="in">
          <div class="min-width-50">
            <b> {{ member_list.member?member_list.member.name:'' }} </b>
            <footer class="text-primary">
              {{ member_list.member.mobile }}|{{ member_list.member.unit?member_list.member.unit.name:'' }}<br>{{
                member_list.member.zone?member_list.member.zone.name:''
              }},{{ member_list.member.district?member_list.member.district.name:'' }}<br><span class="text-danger">{{ member_list.department_name }}</span>
            </footer>
          </div>
          <div class="card-button dropdown">
            <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
              :data-bs-target="'#actionSheetInset' + member_list.member.id" @click="showActionModel">
              <ion-icon name="ellipsis-vertical-outline"></ion-icon>
            </button>
          </div>

          <!-- Default Action Sheet Inset -->
          <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.member.id" tabindex="-1"
            role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Action</h5>
                </div>
                <div class="modal-body">
                  <ul class="action-button-list">
                    <li>
                      <a href="javascript:void(0)" @click="assignModel(member_list)" class="btn btn-list"
                        data-bs-dismiss="modal">
                        <span> <ion-icon name="people-circle-outline"></ion-icon>Assign to Wings</span>
                      </a>
                    </li>
                    <li>
                      <a :href="'tel:' + member_list.member.mobile" class="btn btn-list">
                        <span> <ion-icon name="call-outline"></ion-icon> Call </span>
                      </a>
                    </li>
                    <li v-if="member_list.member.whatsapp">
                      <a :href="'https://wa.me/' +
                        member_list.member.whatsapp_country_code +
                        member_list.member.whatsapp +
                        '?text='
                        " class="btn btn-list">
                        <span> <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp </span>
                      </a>
                    </li>
                    <li class="action-divider"></li>
                    <li>
                      <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal" @click="closeNewAddModel">
                        <span> <ion-icon name="close-outline"></ion-icon> Cancel </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!-- * Default Action Sheet Inset -->
        </div>
      </a>
    </li>
  </ul>
  <div class="section mt-2" v-if="!loading_list">
    <vue-awesome-paginate v-if="responseData.members.total > 0" :total-items="responseData.members.total"
      :items-per-page="responseData.members.per_page" :max-pages-shown="3" v-model="responseData.members.current_page"
      :on-click="getMembersList" :show-ending-buttons="true" :show-breakpoint-buttons="false" />
  </div>
  <div class="modal fade action-sheet" id="assignModel" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Assign to Wing</h5>
        </div>
        <div class="modal-body">
          <div class="action-sheet-content">
            <Form @submit="saveAssign" :validation-schema="assign">
              <div class="form-group basic">
                <div class="input-wrapper">
                  <input type="hidden" v-model="editable_id" />
                  <label class="label mal-text text-primary" for="stay"><span>വിങ്</span></label>
                  <select as="select" class="form-control custom-select" v-model="wing_department" name="wing_department"
                    id="select4">
                    <option value>--Select--</option>
                    <option v-for="wing_department in wing_department_list" v-bind:key="wing_department"
                      v-bind:value="wing_department.id">
                      {{ wing_department.name }}
                    </option>
                  </select>
                  <span id="wing_department_error" class="error-feedback"></span>
                </div>
              </div>
              <div class="form-group basic">
                <button type="button" @click="saveAssign" class="btn btn-primary btn-block btn-lg" :disabled="loading"
                  v-if="responseData.permissions.assign">
                  <span v-show="loading" class="spinner-border spinner-border-sm"></span>
                  <span>Save</span></button>
                <button type="button" class="btn btn-primary btn-block btn-lg" v-if="!responseData.permissions.assign"
                  @click="closeNewAddModel">Close</button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
  <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
    ref="dialog_model"></dialog-model>
</template>

<script>
import UserService from "../../../services/user.service";
import $ from "jquery";
import AlertModel from "./../../layouts/AlertModel.vue";
import DialogModel from "./../../layouts/DialogModel.vue";
export default {
  name: "Admin List",
  components: {
    AlertModel,
    DialogModel,
  },
  props: {
    unit_id: null,
  },
  data() {
    return {
      loading: false,
      loading_list: false,
      search_qty: "",
      filter_type: "gents",
      alert_type: "",
      alert_message: "",
      dialog_message: "",
      dialog_action: "",
      dialog_action_params: [],
      filter_department: "",
      filter_wing: "",
      wing_department_list: "",
      wing_department: '',
      department_list: [],
      district_list: [],
      filter_wings: '',
      filter_district:'',
      responseData: {
        permissions: [],
        members: [],
        event: []
      }
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {
    if (!this.currentUser) {
      location.replace("/");
    } else {
      this.user_level = parseInt(this.$route.params.sub_level);
      this.getDistrictList();
      this.getMembersList(1);
      this.$root.updateParent('Volunteer Report');
    }
    window.onpopstate = function () {
      if ($("body").hasClass("modal-open")) {
        location.reload();
      }
    };
  },
  methods: {
    getComboboxValues(code, id = null) {
      var data = {
        code: code,
        type_id: id,
        member_id: this.member_id,
      };
      UserService.getCombobox(data).then(
        (response) => {
          switch (code) {
            case this.responseData.event.event_capital + "_VDG":
              this.department_list = response.data.data.list.options;
              break;
            case this.responseData.event.event_capital + "_VDL":
              this.department_list = response.data.data.list.options;
              break;
            case this.responseData.event.event_capital + "_VWG":
              this.wing_department_list = response.data.data.list.options;
              break
            case this.responseData.event.event_capital + "_VWL":
              this.wing_department_list = response.data.data.list.options;
              break
            default:
              break;
          }
        },
        (error) => {
          this.loading = false;
          this.messafe =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    getMembersList(page = 1, filter_department = null, search_qry = null, filter_type = 'gents', filter_wing = null, filter_c_type = null) {
      this.loading_list = true;
      if (filter_c_type == 'dept') {
        this.filter_wing = '';
      }
      if (filter_c_type == 'wing') {
        this.filter_department = '';
      }
      let data = {
        event_type: this.$route.params.slug,
        user_level: this.user_level,
        user_level_id: parseInt(this.$route.params.id),
        department: filter_department ? filter_department : this.filter_department,
        q: search_qry ? search_qry : this.search_qry,
        filter_type: filter_type ? this.filter_type : this.filter_type,
        filter_wing: filter_wing ? this.filter_wing : this.filter_wing,
        filter_wings: this.filter_wings,
        filter_district: this.filter_district,
      };
      UserService.authPostRequest(`get-event-volunteers-report?page=${page}`, data).then(
        (response) => {
          if (response.data.status == "success") {
            this.responseData = response.data.data;
            if (this.filter_type == 'gents') {
              this.getComboboxValues(this.responseData.event.event_capital + "_VDG");
              this.getComboboxValues(this.responseData.event.event_capital + "_VWG");
            } else {
              this.getComboboxValues(this.responseData.event.event_capital + "_VDL");
              this.getComboboxValues(this.responseData.event.event_capital + "_VWL");
            }
            this.loading_list = false;
          } else {
            this.loading_list = true;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    saveAssign() {
      this.loading = true;
      let data = {
        editable_id: this.editable_id,
        wing_id: this.wing_department
      };
      UserService.authPostRequest(`assign-event-volunteer`, data).then(
        (response) => {
          if (response.data.status == "success") {
            $("#assignModel").modal("hide");
            this.loading = false;
            this.alert_type = "Success";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            // this.responseData = response.data.data;

          } else {
            this.alert_type = "Warning";
            this.alert_message = response.data.message;
            this.$refs.alert_model.openModel();
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
    closeNewAddModel() {
      $("#assignModel").modal("hide");
    },
    assignModel(data) {
      this.editable_id = data.id;
      $("#assignModel").modal("show");
    },
    getDistrictList() {
      // this.loading_list = true;
      let data = {}
      UserService.authPostRequest(`get-district-by-id`, data).then(
        (response) => {
          if (response.data.status == 'success') {
            this.district_list = response.data.data.list;
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>
<style scoped>
.section-heading {
  padding: 10px 10px 0px 10px;
}

.error-feedback {
  color: red;
}

.listview {
  /* margin-bottom: 20px; */
}

.edit-btn {
  margin-left: 8px;
}

.progress {
  width: 80px;
}

.listview>li footer {
  color: #171617;
}

.min-width-50 {
  min-width: 60% !important;
}

.action-button-list>li {
  min-height: 1px !important;
}

.sl-no {
  font-weight: 900;
  margin-right: 12px;
}

.deleted-list-title {
  font-size: 15px;
}

.deleted-list {
  margin-bottom: 20px;
}

.deleted-info {
  background-color: #f2bbca;
  font-size: 12px;
}

.deleted-info strong {
  font-size: 18px;
  font-weight: 600;
}

.image-listview.text>li:after {
  left: 0px;
  /* height: 1px; */
  background-color: #1a418c;
}

.verification-badge {
  margin-left: 5px;
}

.avatar-section .button {
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 24px;
  bottom: 0;
}

.avatar-section .verified {
  background: #4dc81c;
}

.avatar-section .not-verified {
  background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
  width: 100%;
  height: 48px;
  display: inline-flex;
  align-items: left;
  justify-content: left;
  border-radius: 10px;
  color: #fff;
  /* font-size: 28px; */
  margin-bottom: 14px;
  padding-left: 10px;
}

.wallet-card {
  padding-bottom: 0px !important;
}

.menu-title {
  color: #fff !important;
  font-size: 12px !important;
  padding-left: 5px !important;
  text-align: left !important;
}
</style>
