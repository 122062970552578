<template>
    <div class="section pt-0 mt-0">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 custom-title">{{ page_title }} Report</h3>
        </div>
    </div>
    <div class="section inset mt-1 mb-1"
        v-if="currentUser.user_level <= 3 || this.permissions.report_level <= 3">
        <div class="accordion" id="accordionExample2">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#accordion001" aria-expanded="false">
                        <ion-icon name="filter-outline" role="img" class="md hydrated"
                            aria-label="wallet outline"></ion-icon>
                        Filter
                    </button>
                </h2>
                <div id="accordion001" class="accordion-collapse collapse show" data-bs-parent="#accordionExample2" style="">
                    <div class="accordion-body">
                        <div id="search" style="padding: 0px !important;">
                            <div class="form-group" v-if="currentUser.user_level == 1 || this.permissions.report_level == 1">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_district">District</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_district"
                                        name="filter_district" id="select4">
                                        <option value>---All---</option>
                                        <option v-for="district in district_list" v-bind:value="district.id"
                                            v-bind:key="district.id">{{
                                                district.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!-- <div class="form-group" v-if="currentUser.user_level <= 2">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_zone">Zone</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_zone"
                                        name="filter_zone" id="select4" @change="getUnitList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="zone in zone_list" v-bind:value="zone.id" v-bind:key="zone.id">{{
                                            zone.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 3">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_unit">Unit</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_unit"
                                        name="filter_unit" id="select4">
                                        <option value>---All---</option>
                                        <option v-for="unit in unit_list" v-bind:value="unit.id" v-bind:key="unit.id">{{
                                            unit.name }}
                                        </option>
                                    </select>
                                </div>
                            </div> -->
                            <div class="form-group">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_percentage">Completed Status</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_percentage"
                                        name="filter_percentage" id="select4">
                                        <option value>---All---</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Started">Started</option>
                                        <option value="Not Started">Not Started</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_sex">Sex</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_sex"
                                        name="filter_sex" id="select4">
                                        <option value>---All---</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button type="button" class="btn btn-danger btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="clearFilter()">
                                        <ion-icon name="trash-bin-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Clear
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button type="button" class="btn btn-primary btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="getMembersList()">
                                        <ion-icon name="search-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="section" v-if="!loading_list">
        <div class="row mt-1">
            <div class="col-12">
                <div class="stat-box">
                    <div class="title text-primary">Total</div>
                    <div class="value text-primary">{{ count_target }}</div>
                </div>
            </div>
            <div class="col-6 mt-1">
                <div class="stat-box">
                    <div class="title text-primary">Calling Done</div>
                    <div class="value text-success">{{ count_confirmed }}</div>
                </div>
            </div>
            <div class="col-6 mt-1">
                <div class="stat-box">
                    <div class="title text-primary">Calling Balance</div>
                    <div class="value text-danger">{{ count_total }}</div>
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-6">
                <div class="stat-box">
                    <div class="title text-primary">Registered</div>
                    <div class="value text-success">{{ count_confirmed_reg }}</div>
                </div>
            </div>
            <div class="col-6">
                <div class="stat-box">
                    <div class="title text-primary">Not Registered</div>
                    <div class="value text-danger">{{ count_balance_reg }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="section inset mt-1">
        <div id="search" style="padding: 0px !important;">
            <form class="search-form">
                <div class="row">
                    <!-- <div class="input-group">
                        <label class="block"><input type="radio" name="search_mode" value="mobile" v-model="search_mode">Mobile</label>
                        &nbsp;&nbsp;
                        <label class="block"><input type="radio" name="search_mode" value="reg_no" v-model="search_mode">Reg No</label>
                    </div> -->
                    <div class="input-group form-group" style="width:65%;">
                        <input type="text" placeholder="Search..." class="form-control" focus v-model="search_qry"
                            style="border-radius: 10px;" />
                    </div>
                    <div class="form-group pl-0" style="width:10% !important;">
                        <button type="button" :disabled="search_qry ? false : true" class="btn btn-outline-primary me-1"
                            @click="getMembersList">
                            <ion-icon name="search-outline" role="img" class="md hydrated"
                                aria-label="newspaper outline"></ion-icon>Search
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div class="section text-center" v-if="loading_list">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section mt-0 mb-2" v-if="!loading_list">
        <div class="card">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Total</th>
                            <th scope="col">Done</th>
                            <th scope="col">Balance</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(sub_level,index) in member_lists" v-bind:key="sub_level">
                            <td class="text-primary font-dark text-left">
                                <span class="text-primary"><b> {{ index+1 }}. </b></span> 
                                <router-link :to="'/call-follow-up/' + $route.params.id + '/' + sub_level.phone">
                                    {{ sub_level.name }}<br> <span class="text-dark">{{ sub_level.phone }}</span>
                                </router-link>
                            
                                
                            </td>
                            <td class="text-primary font-dark text-right">
                                {{ sub_level.total_count }}
                            </td>
                            <td class="text-success font-dark text-right">
                                {{ sub_level.completed_count }}
                            </td>
                            <td class="text-danger font-dark text-right">
                                {{ sub_level.not_completed_count }}
                            </td>
                            <td class="text-danger font-dark text-right">
                                <div class="card-button dropdown">
                                    <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                                        :data-bs-target="'#actionSheetInset' + sub_level.phone">
                                        <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                                    </button>
                                </div>
                                <div class="modal fade action-sheet" :id="'actionSheetInset' + sub_level.phone"
                                    tabindex="-1" role="dialog">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title">Action</h5>
                                            </div>
                                            <div class="modal-body">
                                                <ul class="action-button-list">
                                                    <!-- <li>
                                                        <router-link
                                                            :to="'/call-follow-up/' + $route.params.id + '/' + sub_level.phone" data-bs-dismiss="modal">
                                                            <a href="javascript:void(0)" class="btn btn-list"
                                                                >
                                                                <span>
                                                                    <ion-icon name="eye-outline"></ion-icon> View
                                                                </span>
                                                            </a>
                                                        </router-link>
                                                    </li> -->
                                                    <li>
                                                        <a :href="'tel:' + sub_level.phone" class="btn btn-list">
                                                            <span>
                                                                <ion-icon name="call-outline"></ion-icon> Call
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li v-if="sub_level.phone">
                                                        <a :href="'https://wa.me/' + sub_level.phone + '?text='"
                                                            class="btn btn-list">
                                                            <span>
                                                                <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li class="action-divider"></li>
                                                    <li>
                                                        <a href="#" class="btn btn-list text-danger"
                                                            data-bs-dismiss="modal">
                                                            <span>
                                                                <ion-icon name="close-outline"></ion-icon> Cancel
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import UserService from "../../services/user.service";
import $ from 'jquery';
import AlertModel from "./../layouts/AlertModel.vue";
import DialogModel from "./../layouts/DialogModel.vue";
export default {
    name: 'Nerpatham Report',
    components: {
        AlertModel,
        DialogModel
    },
    props: {
        unit_id: null
    },
    data() {
        return {
            loading: false,
            loading_list: false,
            meeting_list: [
                {
                    'id': 1,
                    'name': 'Secretariate'
                },
                {
                    'id': 2,
                    'name': 'Executive'
                },
                {
                    'id': 3,
                    'name': 'Departmental'
                },
                {
                    'id': 4,
                    'name': 'General Body'
                },
                {
                    'id': 5,
                    'name': 'Council'
                },
                {
                    'id': 6,
                    'name': 'Joint Secretariat'
                },
                {
                    'id': 7,
                    'name': 'Wisdom Day'
                },
                {
                    'id': 8,
                    'name': 'Special Meetings'
                },
            ],
            filter_meeting: '',
            search_mode:'',
            search_qry:'',
            filter_date_from: '',
            filter_date_to: '',
            message: "",
            country_code: '91',
            country_code_whatsapp: '91',
            mobile_number: '',
            whatsapp_number: '',
            wing: '',
            stay: '',
            user_level: "",
            sub_levels: [],
            member_lists: [],
            member_list: [],
            member: [],
            member_edit: true,
            orgnal_member_lists: [],
            trash_list: [],
            unit: [],
            editable_id: '',
            editable: false,
            permissions: [],
            add_admin: false,
            abroad_fields: false,
            pin_number: '',
            attendance: 'A',
            attendance_remarks: '',
            search_qty: '',
            member_id: '',
            alert_type: '',
            alert_message: '',
            dialog_message: '',
            dialog_action: '',
            dialog_action_params: [],
            attendance_present: [],
            attendance_absent: [],
            filter_wing: '',
            isMember: '',
            isSubscriber: '',
            receipt_no: '',
            subscription_no: '',
            isEdit: false,
            count_total: 0,
            count_target: 0,
            level: [],
            nerpatham_target: '',
            target_title: 'ടാർഗറ്റ്',
            page_title: '',
            series: [0, 0],
            chartOptions: {
                chart: {
                    width: '100%',
                    type: 'pie',
                },
                colors: ['#64b167', '#db7491'],
                labels: ['Target', 'Total'],
                responsive: [{
                    breakpoint: 100,
                    options: {
                        chart: {
                            width: '100%'
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
            count_state: 0,
            count_unit: 0,
            count_zone: 0,
            count_district: 0,
            count_confirmed: 0.00,
            count_confirmed_reg: 0.00,
            count_balance_reg: 0.00,
            district_list:[],
            filter_district:'',
            filter_percentage:'',
            filter_sex:'',

        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        minusToZero() {
            let value = this.count_target - this.count_total;
            if (value <= 0) {
                return '0';
            } else {
                return value.toFixed(2);
            }
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace('/');
        } else {
            this.getDistrictList();
            this.getMembersList();
            this.$root.updateParent('Call Campign Report');
        }
        window.onpopstate = function () {
            if ($('body').hasClass('modal-open')) {
                location.reload();
            }
        };
    },
    methods: {
        getMembersList() {
            let data = {
                id: parseInt(this.$route.params.id),
                filter_district: this.filter_district ? this.filter_district : '',
                filter_percentage: this.filter_percentage ? this.filter_percentage : '',
                filter_sex: this.filter_sex ? this.filter_sex : '',
                search_qry: this.search_qry ? this.search_qry : '',
            }
            this.loading_list = true;
            UserService.authPostRequest(`get-call-campign-report`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.member_lists = response.data.data.list;
                        this.permissions = response.data.data.permissions;
                        this.count_target = response.data.data.total;
                        this.count_confirmed = response.data.data.done;
                        this.count_total = response.data.data.balance;
                        this.count_confirmed_reg = response.data.data.reg_total;
                        this.count_balance_reg = response.data.data.reg_balance;
                        this.page_title = response.data.data.campaign.name;
                        this.loading_list = false;
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getDistrictList() {
            let data = {}
            UserService.authPostRequest(`get-district-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.district_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
};
</script>
<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.stat-box .value {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: -0.02em;
    line-height: 1em;
    color: #27173E;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.wallet-card .wallet-footer .item .icon-wrapper {
    width: 100%;
    height: 48px;
    display: inline-flex;
    align-items: left;
    justify-content: left;
    border-radius: 10px;
    color: #fff;
    /* font-size: 28px; */
    margin-bottom: 14px;
    padding-left: 10px;
}

.wallet-card {
    padding-bottom: 0px !important;

}

.menu-title {
    color: #fff !important;
    font-size: 12px !important;
    padding-left: 5px !important;
    text-align: left !important;
}

.wallet-card .balance .total {
    font-weight: 700;
    letter-spacing: -0.01em;
    line-height: 1em;
    font-size: 26px;
}

.wallet-card .balance .title {
    color: #27173E;
    font-weight: 500;
    font-size: 16px !important;
    display: block;
    margin-bottom: 8px;
}

.stat-box {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 10px 10px;
}

.wallet-card .balance {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    height: auto;
}

.left {
    margin-left: 0px;
}

.wallet-card {
    background: #ffffff;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 9%);
    border-radius: 10px;
    padding: 0px 10px;
    position: relative;
    z-index: 1;
}

.badge {
    border-radius: 0px !important;
}
</style>