<template>
    <div class="section text-center mt-1" v-if="loading_list">
        <div class="spinner-border text-primary" role="status"></div>
    </div>
    <div class="section pt-0 mt-0" v-if="!loading_list">
        <div class="listed-detail mt-1 page-title">
            <h3 class="text-center mt-1 mal-text custom-title">{{ this.responseData.page_heading }}</h3>
        </div>
    </div>
    <!-- <div class="section mb-1 mt-1" v-if="!loading_list && responseData.permissions.report">
        <div class="transactions">
            <a :href="'/#/call-follow-up-report/' + responseData.campign.id" class="item">
                <div class="detail">
                    <img :src="'assets/img/telephone.png'" alt="img" class="image-block imaged w48">
                    <div>
                        <strong class="text-primary">Calling Report</strong>
                        <p class="mal-text">റിപ്പോർട്ട് പേജിലേക്ക് പോകാൻ ഇവിടെ ക്ലിക്ക് ചെയ്യുക</p>
                    </div>
                </div>
                <div class="right">
                    <div class="price text-primary"><ion-icon name="chevron-forward-outline"></ion-icon></div>
                </div>
            </a>
        </div>
    </div> -->
    <div class="section mb-1" v-if="!loading_list">
        <div class="row mt-1">
            <div class="col-6">
                <div class="stat-box">
                    <div class="title">Total</div>
                    <div class="value text-success">{{ responseData.all_contacts ? responseData.all_contacts.total : '0' }}
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="stat-box">
                    <div class="title">Visited
                        <!-- (<a href="javascript:void(0)" data-bs-toggle="modal"
                            data-bs-target="#pendingListModel">List</a>) -->
                    </div>
                    <div class="value text-danger">{{ responseData.balance ? responseData.balance : '0' }}</div>
                </div>
            </div>
        </div>
    </div>
    <div class="section inset mt-1 mb-1" v-if="currentUser.user_level <= 3 && !loading_list">
        <div class="accordion" id="accordionExample2">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#accordion001" aria-expanded="false">
                        <ion-icon name="filter-outline" role="img" class="md hydrated"
                            aria-label="wallet outline"></ion-icon>
                        Filter
                    </button>
                </h2>
                <div id="accordion001" class="accordion-collapse collapse"
                    :class="(filter_district || filter_zone) ? 'show' : ''" data-bs-parent="#accordionExample2" style="">
                    <div class="accordion-body">
                        <div id="search" style="padding: 0px !important;">
                            <div class="form-group" v-if="currentUser.user_level == 1">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_district">District</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_district"
                                        name="filter_district" id="select4" @change="getZoneList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="district in district_list" v-bind:value="district.id"
                                            v-bind:key="district.id">{{
                                                district.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group" v-if="currentUser.user_level <= 2">
                                <div class="input-wrapper">
                                    <label class="label" for="filter_zone">Zone</label>
                                    <select as="select" class="form-control custom-select" v-model="filter_zone"
                                        name="filter_zone" id="select4" @change="getUnitList($event.target.value)">
                                        <option value>---All---</option>
                                        <option v-for="zone in zone_list" v-bind:value="zone.id" v-bind:key="zone.id">{{
                                            zone.name }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button type="button" class="btn btn-danger btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="clearFilter()">
                                        <ion-icon name="trash-bin-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Clear
                                    </button>
                                </div>
                                <div class="col-6">
                                    <button type="button" class="btn btn-primary btn-sm me-1 mb-1" style="width: 100%;"
                                        @click="getCampign(1)">
                                        <ion-icon name="search-outline" role="img" class="md hydrated"
                                            aria-label="add outline"></ion-icon>
                                        Filter
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="search" v-if="!loading_list && !$route.params.mobile">
        <form class="search-form">
            <div class="row">
                <div class="input-group form-group" style="width:70% !important;padding-right: 0px;">
                    <input type="number" placeholder="Search Mobile..." class="form-control" maxlength="99999999999" focus
                        v-model="search_qry" aria-describedby="basic-addon3"
                        style="border: 1px solid #1a418c; border-radius: 10px;" />
                </div>
                <div class="form-group" style="width:12% !important;">
                    <button type="button" class="btn btn-icon btn-outline-success me-1" @click="search()">
                        <ion-icon name="search-outline" role="img" class="md hydrated"
                            aria-label="newspaper outline"></ion-icon>
                    </button>
                </div>
                <div class="form-group" style="width:12% !important;">
                    <button type="button" class="btn btn-icon btn-outline-primary me-1" @click="displayAll">
                        <ion-icon name="list-outline" role="img" class="md hydrated"
                            aria-label="newspaper outline"></ion-icon>
                    </button>
                </div>
            </div>
        </form>
    </div>
    <div class="section text-center mt-1" v-if="search_loading">
        <div class="spinner-border text-secondary" role="status"></div>
    </div>
    <div class="section pt-0 mt-0" v-if="searchResponse.message">
        <div class="listed-detail mt-1">
            <h4 class="text-center text-danger mt-1 mal-text">{{ searchResponse.message }}</h4>
        </div>
    </div>
    <ul class="listview image-listview inset text mb-2" v-if="!loading_list && !searchResponse.contact && !search_loading">
        <li v-for="(member_list, index) in responseData.all_contacts.data" v-bind:key="member_list.id"
            :style="{ 'background-color': background }">
            <a href="javascript:void(0)" class="item">
                <span class="sl-no">{{ responseData.all_contacts.from + index }}</span>
                <div class="avatar-section">
                    <a href="javascript:void(0)">
                        <img src="assets/img/sample/avatar/avatar1.png" alt="avatar" class="image">
                        <span class="button verified" v-if="member_list.visited_status == 'Visited'">
                            <ion-icon name="checkmark-outline"></ion-icon>
                        </span>
                        <span class="button not-verified" v-else>
                            <ion-icon name="close-outline"></ion-icon>
                        </span>
                    </a>
                </div>
                <div class="in">
                    <div class="min-width-50">
                        <header class="text-info" v-if="member_list.registered == 'Y'">Registered</header>
                        <header class="text-danger" v-else>Not Registered</header>
                        <!-- <header>User</header> -->
                        <b class="text-primary"> {{ member_list.name }} -{{ member_list.contact_id }} </b>
                        <footer
                            :class="member_list.calling_status_value ? member_list.calling_status_value.item_value_display : 'text-primary'">
                            {{
                                member_list.calling_status_value ? member_list.calling_status_value.name_mal :
                                member_list.calling_status
                            }}
                        </footer>
                    </div>
                    <div class="card-button dropdown">
                        <button type="button" class="btn btn-link btn-icon" data-bs-toggle="modal"
                            :data-bs-target="'#actionSheetInset' + member_list.id" @click="showActionModel">
                            <ion-icon name="ellipsis-vertical-outline"></ion-icon>
                        </button>
                    </div>

                    <!-- Default Action Sheet Inset -->
                    <div class="modal fade action-sheet" :id="'actionSheetInset' + member_list.id" tabindex="-1"
                        role="dialog">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title">Action</h5>
                                </div>
                                <div class="modal-body">
                                    <ul class="action-button-list">
                                        <li v-if="responseData.campign.reporting_campaign_id">
                                            <a href="javascript:void(0)" class="btn btn-list text-info"
                                                data-bs-dismiss="modal" @click="gotoReport(member_list.mobile)">
                                                <span>
                                                    <ion-icon name="list-outline"></ion-icon> Goto Report
                                                </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a :href="'tel:' + member_list.mobile" class="btn btn-list text-primary">
                                                <span>
                                                    <ion-icon name="call-outline"></ion-icon> Call
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="member_list.mobile">
                                            <a :href="'https://wa.me/' + mobileToWhatsapp(member_list.mobile) + '?text='"
                                                class="btn btn-list text-success">
                                                <span>
                                                    <ion-icon name="logo-whatsapp"></ion-icon> Whatsapp
                                                </span>
                                            </a>
                                        </li>
                                        <li v-if="!$route.params.mobile">
                                            <a href="javascript:void(0)" class="btn btn-list text-primary"
                                                data-bs-dismiss="modal" @click="search(member_list.mobile)">
                                                <span>
                                                    <ion-icon name="pencil-outline"></ion-icon> View & Update Status
                                                </span>
                                            </a>
                                        </li>

                                        <li class="action-divider"></li>
                                        <li>
                                            <a href="#" class="btn btn-list text-danger" data-bs-dismiss="modal">
                                                <span>
                                                    <ion-icon name="close-outline"></ion-icon> Cancel
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- * Default Action Sheet Inset -->
                </div>
            </a>
        </li>
    </ul>
    <div class="section pt-0 mt-1 pb-2" v-if="searchResponse.contact && !search_loading">
        <div class="card">
            <div class="card-body">
                <Form @submit="handleRegistration" :validation-schema="schema">

                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="id">
                                ID
                            </label>
                            <Field type="text" disabled class="form-control" name="id" v-model="formData.id"
                                placeholder="ID" autocomplete="off" />
                            <ErrorMessage name="id" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="id">
                                വിസ്‌ഡം മെമ്പർ ആണോ?
                            </label>
                            <Field type="text" disabled class="form-control" name="wisdom_member"
                                v-model="formData.wisdom_member" placeholder="" autocomplete="off" />
                            <ErrorMessage name="wisdom_member" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="name">
                                പേര്
                            </label>
                            <Field type="text" disabled class="form-control" name="name" v-model="formData.name"
                                placeholder="Name" autocomplete="off" />
                            <ErrorMessage name="name" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="mobile">
                                മൊബൈൽ
                            </label>
                            <Field type="number" disabled class="form-control" name="mobile" v-model="formData.mobile"
                                placeholder="0000000000" autocomplete="off" />
                            <ErrorMessage name="mobile" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="status">
                                വിളിയുടെ status
                            </label>
                            <Field as="select" disabled class="form-control custom-select" id="status" name="status"
                                v-model="formData.status">
                                <option value="" :disabled="true" selected>Select Status</option>
                                <option v-for="status in status_list" v-bind:key="status.id" v-bind:value="status.id">
                                    {{ status.name_mal }}
                                </option>
                            </Field>
                            <ErrorMessage name="status" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="mobile2">
                                മൊബൈൽ 2
                            </label>
                            <Field type="number" disabled class="form-control" name="mobile2" v-model="formData.mobile2"
                                placeholder="0000000000" autocomplete="off" />
                            <ErrorMessage name="mobile2" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="sex">
                                Sex</label>
                            <div class="form-check form-check-inline">
                                <input type="radio" disabled class="form-check-input" value="Male" name="Sex" id="sex_1"
                                    v-model="formData.sex" />
                                <label class="form-check-label" for="sex_1">Male &nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" disabled class="form-check-input" value="Female" name="Sex" id="sex_2"
                                    v-model="formData.sex" />
                                <label class="form-check-label" for="sex_2">Female &nbsp;</label>
                            </div>
                            <ErrorMessage name="sex" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="district">
                                ജില്ല
                            </label>
                            <Field as="select" disabled class="form-control custom-select" id="district" name="district"
                                v-model="formData.district">
                                <option value="" :disabled="true" selected>Select District</option>
                                <option v-for="district in district_list" v-bind:key="district.id"
                                    v-bind:value="district.id">
                                    {{ district.name }}
                                </option>
                            </Field>
                            <ErrorMessage name="place" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="place">
                                സ്ഥലം
                            </label>
                            <Field type="text" disabled class="form-control" name="place" v-model="formData.place"
                                placeholder="Place" autocomplete="off" />
                            <ErrorMessage name="place" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label mal-text text-primary" for="comments">
                                അവർ പറഞ്ഞ അഭിപ്രായങ്ങൾ, നിർദ്ദേശങ്ങൾ
                            </label>
                            <Field type="text" disabled class="form-control" name="comments" v-model="formData.comments"
                                placeholder="---" autocomplete="off" />
                            <ErrorMessage name="comments" class="error-feedback" />
                        </div>
                    </div>
                    <div class="form-group basic" v-for="(questian, index) in responseData.questians"
                        v-bind:key="questian.id">
                        <div class="input-wrapper" v-if="questian.question_type == 'DATE'">
                            <label class="label questians-label text-primary mal-text" :for="'field' + questian.id">
                                <b>{{ questian.question_text }}</b>
                            </label>
                            <Field type="date" disabled class="form-control" :name="'questian_air[' + index + ']'"
                                placeholder="..." v-model="questians_responses[index]"
                                :required="questian.question_validations == 'Y'" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <div class="input-wrapper" v-if="questian.question_type == 'TX' || questian.question_type == 'TXT'">
                            <label class="label questians-label text-primary mal-text" :for="'field' + questian.id">
                                <b>{{ questian.question_text }}</b>
                            </label>
                            <Field type="text" disabled class="form-control" :name="'questian_air[' + index + ']'"
                                placeholder="..." v-model="questians_responses[index]"
                                :required="questian.question_validations == 'Y'" />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                        <div class="input-wrapper" v-else-if="questian.question_type == 'SB'">
                            <label class="label questians-label text-primary mal-text" :for="'field' + questian.id">
                                <b>{{ questian.question_text }}</b>
                            </label>
                            <select as="select" disabled class="form-control custom-select"
                                v-model="questians_responses[index]" :required="questian.question_validations == 'Y'"
                                :name="'questian_air[' + index + ']'" id="select4">
                                <option value>---Select---</option>
                                <option v-for="option in convertToJson(questian.question_options)" v-bind:value="option"
                                    v-bind:key="option">{{ option }}</option>
                            </select>
                        </div>

                    </div>
                    <div class="form-group basic">
                        <div class="input-wrapper">
                            <label class="label text-primary" for="visited_status">
                                Visited Status</label>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="Visited" name="visited_status"
                                    id="visited_status_1" v-model="formData.visited_status" />
                                <label class="form-check-label" for="visited_status_1">Visited &nbsp;</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" class="form-check-input" value="Not Visited" name="visited_status"
                                    id="visited_status_2" v-model="formData.visited_status" />
                                <label class="form-check-label" for="visited_status_2">Not Visited &nbsp;</label>
                            </div>
                            <ErrorMessage name="visited_status" class="error-feedback" />
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary btn-block btn-lg" :disabled="btn_loading">
                        <span v-show="btn_loading" class="spinner-border spinner-border-sm"></span>
                        <span>Save</span>
                    </button>
                </Form>
            </div>
        </div>
    </div>
    <div class="section mt-2" v-if="!loading_list">
        <vue-awesome-paginate v-if="responseData.all_contacts.total > 0" :total-items="responseData.all_contacts.total"
            :items-per-page="responseData.all_contacts.per_page" :max-pages-shown="3"
            v-model="responseData.all_contacts.current_page" :on-click="getCampign" :show-ending-buttons="true"
            :show-breakpoint-buttons="false" />
    </div>
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params"
        ref="dialog_model"></dialog-model>
</template>

<script>
import {
    Form,
    Field,
    ErrorMessage
} from "vee-validate";
import * as yup from "yup";
import UserService from "../../../services/user.service";
import $ from "jquery";
import AlertModel from "./../../layouts/AlertModel.vue";
import DialogModel from "./../../layouts/DialogModel.vue";
export default {
    name: "Admin List",
    props: {
        unit_id: null,
        permissions: Object,
    },
    components: {
        Form,
        Field,
        ErrorMessage,
        AlertModel,
        DialogModel,
    },
    data() {
        const schema = yup.object().shape({
            name: yup.string().required("ഫിൽ ചെയ്യുക!"),
            mobile: yup.string().required("ഫിൽ ചെയ്യുക!"),
            // status: yup.string().required("സെലക്റ്റ് ചെയ്യുക!"),
        });
        return {
            btn_loading: false,
            search_loading: false,
            loading: false,
            loading_list: false,
            schema,
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            directory_type_list: [],
            profession_list: [],
            skilset_list: [],
            adarsham_list: [],
            qualification_list: [],
            wisdom_programs_list: [],
            formData: {
                directory_type: [],
                wisdom_programs: [],
                profession: [],
                skilset: [],
                adarsham: [],
                qualification: [],
                name: '',
                mobile: '',
                mobile2: '',
                whatsapp: '',
                age: '',
                remarks: '',
                district: '',
                place: '',
                comments: '',
                status: '',
                wisdom_member: '',
                visited_status: ''
            },
            questians_responses: [],
            responseData: {
                campign: [],
                questians: [],
                all_contacts: [],
                permissions: []
            },
            searchResponse: {
                contact: ''
            },
            pending_contacts: [],
            status_list: [],
            zone_list: [],
            district_list: [],
            filter_district: '',
            filter_zone: '',
        };
    },

    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        childLength() {
            return parseInt(this.formData.lessthan_16_count);
        },
    },
    mounted() {
        if (!this.currentUser) {
            location.replace("/");
        } else {
            this.$root.updateParent("Loading...");
            this.getComboboxValues('CALL_CAMPAIGN_STATUS');
            this.getCampign();
            this.getDistrictList();
            this.getZoneList(this.currentUser.district_id);

        }
        window.onpopstate = function () {
            if ($("body").hasClass("modal-open")) {
                location.reload();
            }
        };
    },
    methods: {
        displayAll() {
            this.search_qry = '';
            this.searchResponse.contact = '';
            this.searchResponse.message = '';
        },
        convertToJson(subject) {
            return JSON.parse(subject.replace(/&quot;/ig, '"'));
        },
        search(mobile = null) {
            if (mobile) {
                this.search_qry = mobile;
            }
            this.search_loading = true;
            let data = {
                q: this.search_qry,
                event_type: this.$route.params.slug,
                level: 'admin'
            }
            UserService.authPostRequest('get-call-campign-contact', data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.search_loading = false;
                        this.searchResponse = response.data.data;
                        this.formData.id = this.searchResponse.contact.contact_id;
                        this.formData.wisdom_member = this.searchResponse.contact.wisdom_member;
                        this.formData.name = this.searchResponse.contact.name;
                        this.formData.mobile = this.searchResponse.contact.mobile;
                        this.formData.mobile2 = this.searchResponse.contact.mobile2;
                        this.formData.sex = this.searchResponse.contact.gender;
                        this.formData.district = this.searchResponse.contact.district_id;
                        this.formData.place = this.searchResponse.contact.place;
                        this.formData.comments = this.searchResponse.contact.comments;
                        this.formData.status = this.searchResponse.contact.calling_status;
                        this.formData.visited_status = this.searchResponse.contact.visited_status;
                        if (this.searchResponse.questian_responses) {
                            let count = 0;
                            this.searchResponse.questian_responses.forEach(element => {
                                this.questians_responses[count] = element.answer_text;
                                count++;
                            });
                        }
                        this.$root.updateParent('Call Campaign');
                    } else {
                        this.search_loading = true;
                    }
                },
                (error) => {
                    this.search_loading = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );
        },
        getComboboxValues(code, id = null) {
            var data = {
                code: code,
                type_id: id,
            };
            UserService.getCombobox(data).then(
                (response) => {
                    switch (code) {
                        case "CALL_CAMPAIGN_STATUS":
                            this.status_list = response.data.data.list.options;
                            break;
                        default:
                            break;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.messafe =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        handleRegistration(data) {
            this.btn_loading = true;
            data.contact_id = data.id;
            data.id = this.searchResponse.contact.id;
            data.visited_status = this.formData.visited_status;
            UserService.authPostRequest("store-campign-status", data).then(
                (response) => {
                    if (response.data.status == "success") {
                        this.alert_type = "Success";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.searchResponse.contact = '',
                            this.search_qry = '';
                        this.getCampign();
                        this.btn_loading = false;
                    } else if (response.data.status == "warning") {
                        this.alert_type = "Warning";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    } else {
                        this.alert_type = "Error";
                        this.alert_message = response.data.message;
                        this.$refs.alert_model.openModel();
                        this.btn_loading = false;
                    }
                },
                (error) => {
                    this.btn_loading = false;
                    this.message =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getCampign(page = 1) {
            this.loading_list = true;
            let data = {
                id: this.$route.params.slug,
                mobile: this.$route.params.mobile ? this.$route.params.mobile : null,
                filter_district: this.filter_district ? this.filter_district : null,
                filter_zone: this.filter_zone ? this.filter_zone : null,
            }
            UserService.authPostRequest(`get-event-call-campign?page=${page}`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.loading_list = false;
                        this.responseData = response.data.data;
                        this.pending_contacts = response.data.data.pending_contacts;
                        this.all_contacts = response.data.data.all_contacts;
                        this.district_list = response.data.data.district;
                        if (this.responseData.questians) {
                            let count = 0;
                            this.responseData.questians.forEach(element => {
                                this.questians_responses[count] = element.answer_text;
                                count++;
                            });
                        }
                        this.$root.updateParent(this.responseData.page_title);
                    } else {
                        this.loading_list = true;
                    }
                },
                (error) => {
                    this.loading_list = false;
                    this.alert_type = 'Error';
                    this.alert_message = error.data.message;
                    this.$refs.alert_model.openModel();
                }
            );
        },
        mobileToWhatsapp(mobileNumber) {
            // Remove any non-digit characters from the input
            const cleanNumber = mobileNumber.replace(/\D/g, '');

            // Check if the cleaned number has exactly 10 digits
            if (cleanNumber.length === 10) {
                // Add "91" to the beginning of the number
                return '91' + cleanNumber;
            } else {
                // If the number doesn't have 10 digits, return it as is
                return cleanNumber;
            }
        },
        gotoReport(mobile) {
            this.$router.push('/call-follow-up/' + this.responseData.campign.reporting_campaign_id + '/' + mobile + '');
        },
        getDistrictList() {
            // this.loading_list = true;
            let data = {}
            UserService.authPostRequest(`get-district-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.district_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        getZoneList(district_id) {
            // this.loading_list = true;
            let data = {
                district_id: district_id
            }
            UserService.authPostRequest(`get-zone-by-id`, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.zone_list = response.data.data.list;
                    }
                },
                (error) => {
                    this.loading = false;
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
        clearFilter() {
            this.filter_district = '';
            this.filter_zone = '';
            this.getCampign();
        },
    },
};
</script>

<style scoped>
.section-heading {
    padding: 10px 10px 0px 10px;
}

.error-feedback {
    color: red;
}

.listview {
    /* margin-bottom: 20px; */
}

.edit-btn {
    margin-left: 8px;
}

.progress {
    width: 80px;
}

.listview>li footer {
    color: #171617;
}

.min-width-50 {
    min-width: 60% !important;
}

.action-button-list>li {
    min-height: 1px !important;
}

.sl-no {
    font-weight: 900;
    margin-right: 12px;
}

.deleted-list-title {
    font-size: 15px;
}

.deleted-list {
    margin-bottom: 20px;
}

.deleted-info {
    background-color: #f2bbca;
    font-size: 12px;
}

.deleted-info strong {
    font-size: 18px;
    font-weight: 600;
}

.image-listview.text>li:after {
    left: 0px;
    /* height: 1px; */
    background-color: #1a418c;
}

.verification-badge {
    margin-left: 5px;
}

.avatar-section .button {
    color: #fff;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    position: absolute;
    right: 10px;
    top: 24px;
    bottom: 0;
}

.avatar-section .verified {
    background: #4dc81c;
}

.avatar-section .not-verified {
    background: #eb3941;
}

.green-tick {
    font-size: 18px;
    color: darkgreen;
}

.red-cross {
    font-size: 18px;
    color: red;
}

.qhls-text {
    font-style: italic;
    color: #266bd1;
}

.custom-file-upload {
    position: relative;
    display: flex;
    width: 50%;
    height: 100px;
}</style>
