<template>
    <!-- App Capsule -->
    <div id="appCapsule">
        <div class="section mb-2 p-2" style="margin-top: -50px">
            <div class="card card-slider">
                <img :src="responseData.donations.image" class="card-img-top" alt="image" />
                <div class="card-body">
                    <h5 class="card-title mal-text text-primary text-center page-title">{{responseData.donations.title}}</h5>
                    <p class="card-text mal-text" v-html="responseData.donations.content" style="color: black !important;">
                    </p>
                    <p style="color: black !important;"> GPay/Phonepe വഴി പണം അയക്കാൻ താഴെ ഉള്ള Pay Now ബട്ടൺ ക്ലിക്ക് ചെയ്യുക.<br />
                        <a :href="responseData.donations.link_text">
                          <button type="button" class="btn btn-danger btn-sm me-1 mb-1">
                                    <ion-icon name="card-outline" role="img" class="md hydrated" aria-label="add outline"></ion-icon>
                                    Pay Now
                          </button></a>
                    </p>
    
                    <p style="color: black !important;" v-if="responseData.donations.link"> ക്യാഷ് അയച്ചവർ <a :href="'http://wa.me/'+responseData.donations.link">{{responseData.donations.link}}</a> നമ്പറിൽ WhatsApp മെസ്സേജ് അയച്ചു റസിപ്റ്റ് നിർബന്ധമായും കൈ പറ്റുമല്ലോ...<br />
                        <a :href="'http://wa.me/'+responseData.donations.link">
                          <button type="button" class="btn btn-success btn-sm me-1 mb-1">
                                    <ion-icon name="logo-whatsapp" role="img" class="md hydrated" aria-label="add outline"></ion-icon>
                                    Whatsapp Now
                          </button></a>
                    </p>
                    <p class="mal-text text-primary">അല്ലാഹുവിന്‍റെ മാര്‍ഗത്തില്‍ തങ്ങളുടെ ധനം ചെലവഴിക്കുന്നവരെ ഉപമിക്കാവുന്നത് ഒരു ധാന്യമണിയോടാകുന്നു. അത് ഏഴ് കതിരുകള്‍ ഉല്‍പാദിപ്പിച്ചു. ഓരോ കതിരിലും നൂറ് ധാന്യമണിയും. അല്ലാഹു താന്‍ ഉദ്ദേശിക്കുന്നവര്‍ക്ക് ഇരട്ടിയായി നല്‍കുന്നു. അല്ലാഹു വിപുലമായ
                        കഴിവുകളുള്ളവനും (എല്ലാം) അറിയുന്നവനുമാണ്‌. (അൽ ബഖറ : 261)</p>
                </div>
            </div>
        </div>
    </div>
    <!-- * Terms Modal -->
    <!-- * App Capsule -->
    <alert-model :type="alert_type" :message="alert_message" ref="alert_model"></alert-model>
    <dialog-model :message="dialog_message" :action_method="dialog_action" :action_method_params="dialog_action_params" ref="dialog_model"></dialog-model>
</template>

<script>
import AlertModel from "../layouts/AlertModel.vue";
import DialogModel from "../layouts/DialogModel.vue";
import UserService from "../../services/user.service";
export default {
    name: "Register",
    components: {
        AlertModel,
        DialogModel,
    },
    data() {
        return {
            alert_type: "",
            alert_message: "",
            dialog_message: "",
            dialog_action: "",
            dialog_action_params: [],
            loading: false,
            responseData: {
                donations: []
            }
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
    },
    mounted() {
        this.$root.updateParent("Loading...");
        this.getDonation();
    },
    methods: {
        getDonation() {
            let data = {
                slug: this.$route.params.slug
            }
            UserService.GetRequest('get-donations?slug=' + this.$route.params.slug, data).then(
                (response) => {
                    if (response.data.status == 'success') {
                        this.responseData = response.data.data;
                        this.$root.updateParent(this.responseData.donations.sub_title);
                    }
                },
                (error) => {
                    this.message =
                        (error.response &&
                            error.response.data &&
                            error.response.data.message) ||
                        error.message ||
                        error.toString();
                }
            );
        },
    },
    created() {},
};
</script>

<style scoped>
.error-feedback {
    color: red;
}

#username {
    margin-top: 0px;
}

.label {
    margin-left: -10px;
    margin-bottom: 0px;
}

.forget {
    font-size: 12px !important;
    text-decoration: underline;
}

.title-reg {
    font-size: 18px;
}
</style>
